import {Table} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {ConfirmDelete} from "../../../shared/components/ConfirmDelete";
import {EmptyState} from "../../../shared/components/EmptyState";
import {Loader} from "../../../shared/components/Loader";

export const List = ({
  brandsList,
  editHandler,
  deleteHandler,
  show,
  setShow,
  selectedPrompt,
  setSelectedPrompt
}: any) => {
  const { t } = useTranslation();
  return (
    <div className="card">
      <div className="card-header pb-0">
        <div className="d-flex justify-content-between">
          <h4 className="card-title mg-b-0">{t("brand.listOfBrands")}</h4>
        </div>
      </div>
      <div className="card-body">
        {brandsList === undefined ? (
          <Loader />
        ) : brandsList.length === 0 ? (
          <EmptyState />
        ) : (
          <div className="table-responsive">
            <Table className="align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr className="">
                  <th className="col-1">{t(`global.id`)}</th>
                  <th>{t(`table_headers.name`)}</th>

                  <th className="col-2 text-center">
                    {t(`table_headers.actions`)}
                  </th>
                </tr>
              </thead>
              <tbody>
                {brandsList?.map((prompt: any) => {
                  return (
                    <tr key={prompt?.id} className="">
                      <td>
                        <Link to="#" className="fw-medium">
                          #{prompt?.id}
                        </Link>
                      </td>
                      <td>{prompt?.name ?? "-"}</td>
                      {/*<td>{prompt?.website ?? "-"}</td>*/}
                      <td className="col-2">
                        <div className="d-flex align-items-center justify-content-center">
                          <button
                            className="btn btn-sm btn-warning d-flex align-items-center me-2"
                            onClick={() => editHandler(prompt?.id)}
                          >
                            <i className="ri-edit-2-fill me-1" />
                            {t("global.edit")}
                          </button>
                          <button
                            className="btn btn-sm btn-danger d-flex align-items-center"
                            onClick={() => {
                                setShow(true)
                                setSelectedPrompt(prompt);
                            }}
                          >
                            <i className="ri-delete-bin-fill me-1" />
                            {t("global.delete")}
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        )}
        <ConfirmDelete
          show={show}
          setShow={setShow}
          itemName={selectedPrompt?.name}
          deleteHandler={deleteHandler}
          product={selectedPrompt}
          selectedProduct={selectedPrompt}
        />
      </div>
    </div>
  );
};
