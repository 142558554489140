import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { PICTURE_URL } from "../../helpers/api.routes";

export const SideBar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const getAuth = useSelector((state: any) => state.auth);
  const checkPermission = (permission: string) => {
    return getAuth?.administrationData?.[
      localStorage.getItem("website")!
    ]?.includes(permission);
  };

  const getPathName = (path: string) => {
    if (path === "/admin/magazine/category") {
      return false;
    } else {
      return path.split("/")[2];
    }
  };
  return (
    <>
      <div className="app-menu navbar-menu">
        {/* <!-- LOGO --> */}
        <div className="navbar-brand-box">
          {getAuth?.companyData?.logo?.path ? (
            <a href={"/app"} className="logo logo-dark">
              <div style={{ width: 150 }}>
                <img
                  className="img-fluid"
                  src={`${PICTURE_URL + getAuth.companyData.logo.path}`}
                  alt=""
                />
              </div>
            </a>
          ) : (
            <a href={"/app"} className="logo logo-dark">
              <div style={{ width: 150 }}>
                <img
                  className="img-fluid"
                  src="/assets/images/logo-1.png"
                  alt=""
                />
              </div>
            </a>
          )}

          <button
            type="button"
            className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
            id="vertical-hover"
          >
            <i className="ri-record-circle-line"></i>
          </button>
        </div>
        <div id="scrollbar">
          <div className="container-fluid">
            <div id="two-column-menu"></div>
            <ul className="navbar-nav" id="navbar-nav">
              <li className="menu-title">
                <span data-key="t-menu"> {t("side_bar.home")}</span>
              </li>

              <li className="nav-item">
                <Link
                  to="/app
                 "
                  className={`nav-link menu-link ${
                    location?.pathname?.includes("/app") && "active"
                  }`}
                >
                  <i className="bi bi-rocket"></i>
                  <span data-key="t-starter">{t("global.dashboard")}</span>
                </Link>
              </li>
              <li className="menu-title">
                <i className="ri-more-fill"></i>
                <span data-key="t-layouts">{t("side_bar.admin")}</span>
              </li>
              {checkPermission("auto_information_news") && (
                  <li className="nav-item">
                    <a
                        className="nav-link menu-link"
                        href="#news-auto-information"
                        data-bs-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                        aria-controls="news-auto-information"
                    >
                      <i className="ri-article-fill"></i>
                      <span data-key="t-detached">{t("news_auto_information.news")}</span>
                    </a>
                    <div className="collapse menu-dropdown" id="news-auto-information">
                      <ul className="nav nav-sm flex-column">
                        <Link
                            data-key="t-level-1.2"
                            to="/admin/news-auto-information"
                            className={`nav-link menu-link  ${
                                location?.pathname === "/admin/news_auto_information" && "active"
                            }`}
                        >
                        <span data-key="t-detached">
                          {t("global.overview")}
                        </span>
                        </Link>
                        <Link
                            data-key="t-level-1.2"
                            to="/admin/news-auto-information/create"
                            className={`nav-link menu-link  ${
                                location?.pathname === "/admin/news/create" &&
                                "active"
                            }`}
                        >
                          <span data-key="t-detached">{t("global.add")}</span>
                        </Link>
                        {checkPermission("auto_information_news_category") && (
                            <Link
                                data-key="t-level-1.2"
                                to="/admin/auto-information/news-category"
                                className={`nav-link menu-link ${
                                    getPathName(location.pathname) === "/auto-information/news-category" &&
                                    "active"
                                }`}
                            >
                          <span data-key="t-detached">
                            {t("table_headers.category")}
                          </span>
                            </Link>
                        )}
                      </ul>
                    </div>
                  </li>
              )}
              {checkPermission("editions") && (
                  <li className="nav-item">
                    <a
                        className="nav-link menu-link"
                        href="#editions"
                        data-bs-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                        aria-controls="editions"
                    >
                      <i className="ri-article-fill"></i>
                      <span data-key="t-detached">{t("editions.editions")}</span>
                    </a>
                    <div className="collapse menu-dropdown" id="editions">
                      <ul className="nav nav-sm flex-column">
                        <Link
                            data-key="t-level-1.2"
                            to="/admin/editions"
                            className={`nav-link menu-link  ${
                                location?.pathname === "/admin/editions" && "active"
                            }`}
                        >
                        <span data-key="t-detached">
                          {t("global.overview")}
                        </span>
                        </Link>
                        <Link
                            data-key="t-level-1.2"
                            to="/admin/editions/create"
                            className={`nav-link menu-link  ${
                                location?.pathname === "/admin/editions/create" &&
                                "active"
                            }`}
                        >
                          <span data-key="t-detached">{t("global.add")}</span>
                        </Link>
                      </ul>
                    </div>
                  </li>
              )}
              {/*{checkPermission("notifications") && (*/}
                  <li className="nav-item">
                    <a
                        className="nav-link menu-link"
                        href="#notifications"
                        data-bs-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                        aria-controls="notifications"
                    >
                      <i className="ri-notification-2-fill"></i>
                      <span data-key="t-detached">{t("notifications.notifications")}</span>
                    </a>
                    <div className="collapse menu-dropdown" id="notifications">
                      <ul className="nav nav-sm flex-column">
                        <Link
                            data-key="t-level-1.2"
                            to="/admin/auto-information/notifications"
                            className={`nav-link menu-link  ${
                                location?.pathname === "/admin/auto-information/notifications" && "active"
                            }`}
                        >
                        <span data-key="t-detached">
                          {t("global.overview")}
                        </span>
                        </Link>
                        <Link
                            data-key="t-level-1.2"
                            to="/admin/notifications/create"
                            className={`nav-link menu-link  ${
                                location?.pathname === "/admin/auto-information/notifications/create" &&
                                "active"
                            }`}
                        >
                          <span data-key="t-detached">{t("global.add")}</span>
                        </Link>
                      </ul>
                    </div>
                  </li>
              {/*)}*/}
              {checkPermission("auto_information_subscriber") && (
                  <li className="nav-item">
                    <a
                        className="nav-link menu-link"
                        href="#subscribers"
                        data-bs-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                        aria-controls="subscribers"
                    >
                      <i className="ri-user-follow-fill"></i>
                      <span data-key="t-detached">{t("subscribers.subscribers")}</span>
                    </a>
                    <div className="collapse menu-dropdown" id="subscribers">
                      <ul className="nav nav-sm flex-column">
                        <Link
                            data-key="t-level-1.2"
                            to="/admin/auto-information/subscriber"
                            className={`nav-link menu-link  ${
                                location?.pathname === "/admin/auto-information/subscriber" && "active"
                            }`}
                        >
                        <span data-key="t-detached">
                          {t("global.overview")}
                        </span>
                        </Link>
                        <Link
                            data-key="t-level-1.2"
                            to="/admin/editions/create"
                            className={`nav-link menu-link  ${
                                location?.pathname === "/admin/auto-information/subscriber/create" &&
                                "active"
                            }`}
                        >
                          <span data-key="t-detached">{t("global.add")}</span>
                        </Link>
                      </ul>
                    </div>
                  </li>
              )}
              {checkPermission("news") && (
                <li className="nav-item">
                  <a
                    className="nav-link menu-link"
                    href="#news"
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="news"
                  >
                    <i className="ri-article-fill"></i>
                    <span data-key="t-detached">{t("news.news")}</span>
                  </a>
                  <div className="collapse menu-dropdown" id="news">
                    <ul className="nav nav-sm flex-column">
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/news"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/news" && "active"
                        }`}
                      >
                        <span data-key="t-detached">
                          {t("global.overview")}
                        </span>
                      </Link>

                      <Link
                        data-key="t-level-1.2"
                        to="/admin/news/create"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/news/create" &&
                          "active"
                        }`}
                      >
                        <span data-key="t-detached">{t("global.add")}</span>
                      </Link>
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/news-theme"
                        className={`nav-link menu-link ${
                          getPathName(location.pathname) === "news-theme" &&
                          "active"
                        }`}
                      >
                        <span data-key="t-detached">
                          {t("table_headers.news_theme")}
                        </span>
                      </Link>
                      {checkPermission("news_category") && (
                        <Link
                          data-key="t-level-1.2"
                          to="/admin/category"
                          className={`nav-link menu-link ${
                            getPathName(location.pathname) === "category" &&
                            "active"
                          }`}
                        >
                          <span data-key="t-detached">
                            {t("table_headers.category")}
                          </span>
                        </Link>
                      )}

                      {checkPermission("news") && (
                        <Link
                          data-key="t-level-1.2"
                          to="/admin/featured-articles"
                          className={`nav-link menu-link ${
                            getPathName(location.pathname) ===
                              "featured-articles" && "active"
                          }`}
                        >
                          <span data-key="t-detached">
                            {t("global.featured_articles")}
                          </span>
                        </Link>
                      )}
                      {checkPermission("news") && (
                        <Link
                          data-key="t-level-1.2"
                          to="/admin/editor-choice"
                          className={`nav-link menu-link ${
                            getPathName(location.pathname) ===
                              "editor-choice" && "active"
                          }`}
                        >
                          <span data-key="t-detached">
                            {t("global.editor_choice")}
                          </span>
                        </Link>
                      )}
                      {checkPermission("news") && (
                        <Link
                          data-key="t-level-1.2"
                          to="/admin/waiting-news"
                          className={`nav-link menu-link ${
                            getPathName(location.pathname) === "waiting-news" &&
                            "active"
                          }`}
                        >
                          <span data-key="t-detached">
                            {t("global.waiting_news")}
                          </span>
                        </Link>
                      )}
                      <a
                          className="nav-link menu-link"
                          href="#in-focus"
                          data-bs-toggle="collapse"
                          role="button"
                          aria-expanded="false"
                          aria-controls="in-focus"
                      >
                        <span data-key="t-detached">{t("global.in_focus")}</span>
                      </a>
                      <div className="collapse menu-dropdown" id="in-focus">
                        <ul className="nav nav-sm flex-column">
                          <Link
                              data-key="t-level-1.2"
                              to="/admin/in-focus/all"
                              className={`nav-link menu-link  ${
                                  location?.pathname === "/admin/in-focus/all" && "active"
                              }`}
                          >
                              <span data-key="t-detached">{t("global.overview")}</span>
                          </Link>

                          <Link
                              data-key="t-level-1.2"
                              to="/admin/news/create?inFocus=true"
                              className={`nav-link menu-link  ${
                                  location?.pathname === "/admin/news/create" &&
                                  "active"
                              }`}
                          >
                            <span data-key="t-detached">{t("global.add")}</span>
                          </Link>
                            <Link
                                data-key="t-level-1.2"
                                to="/admin/in-focus/settings"
                                className={`nav-link menu-link  ${
                                    location?.pathname === "/admin/in-focus/settings" &&
                                    "active"
                                }`}
                            >
                                <span data-key="t-detached">{t("settings.settings")}</span>
                            </Link>
                        </ul>
                      </div>
                    </ul>
                  </div>
                </li>
              )}

              {checkPermission("brand") && (
                  <li className="nav-item">
                    <a
                        className="nav-link menu-link"
                        href="#brand"
                        data-bs-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                        aria-controls="brand"
                    >
                      <i className="ri-vip-crown-fill"></i>
                      <span data-key="t-detached">{t("brand.brand")}</span>
                    </a>
                    <div className="collapse menu-dropdown" id="brand">
                      <ul className="nav nav-sm flex-column">
                        <Link
                            data-key="t-level-1.2"
                            to="/admin/brand"
                            className={`nav-link menu-link  ${
                                location?.pathname === "/admin/download" && "active"
                            }`}
                        >
                        <span data-key="t-detached">
                          {t("global.overview")}
                        </span>
                        </Link>
                        <Link
                            data-key="t-level-1.2"
                            to="/admin/brand-settings"
                            className={`nav-link menu-link  ${
                                location?.pathname === "/admin/brand-settings" &&
                                "active"
                            }`}
                        >
                          <span data-key="t-detached">{t("settings.settings")}</span>
                        </Link>
                      </ul>
                    </div>
                  </li>
              )}
              {checkPermission("tests") && (
                <li className="nav-item">
                  <a
                    className="nav-link menu-link"
                    href="#tests"
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="tests"
                  >
                    <i className="ri-article-fill"></i>
                    <span data-key="t-detached">{t("test.tests")}</span>
                  </a>
                  <div className="collapse menu-dropdown" id="tests">
                    <ul className="nav nav-sm flex-column">
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/tests"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/tests" && "active"
                        }`}
                      >
                        <span data-key="t-detached">
                          {t("global.overview")}
                        </span>
                      </Link>

                      <Link
                        data-key="t-level-1.2"
                        to="/admin/tests/create"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/tests/create" &&
                          "active"
                        }`}
                      >
                        <span data-key="t-detached">{t("global.add")}</span>
                      </Link>
                    </ul>
                  </div>
                </li>
              )}
              {/* model permission */}
              {checkPermission("model") && (
                <li className="nav-item">
                  <a
                    className="nav-link menu-link"
                    href="#model"
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="model"
                  >
                    <i className="ri-camera-lens-fill"></i>
                    <span data-key="t-detached">{t("model.model")}</span>
                  </a>
                  <div className="collapse menu-dropdown" id="model">
                    <ul className="nav nav-sm flex-column">
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/model"
                        className={`nav-link menu-link ${
                          location?.pathname === "admin/model" && "active"
                        }`}
                      >
                        <span data-key="t-detached">
                          {t("global.overview")}
                        </span>
                      </Link>
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/model/create"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/model/create" &&
                          "active"
                        }`}
                      >
                        <span data-key="t-detached">{t("global.add")}</span>
                      </Link>
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/model-category"
                        className={`nav-link menu-link ${
                          location?.pathname?.includes(
                            "/admin/model-category"
                          ) && "active"
                        }`}
                      >
                        <span data-key="t-detached">
                          {t("table_headers.category")}
                        </span>
                      </Link>
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/model-gallery"
                        className={`nav-link menu-link ${
                          location?.pathname?.includes(
                            "/admin/model-gallery"
                          ) && "active"
                        }`}
                      >
                        <span data-key="t-detached">{t("album.album")}</span>
                      </Link>
                    </ul>
                  </div>
                </li>
              )}
              {checkPermission("magazine") && (
                <li className="nav-item">
                  <a
                    className="nav-link menu-link"
                    href="#magazine"
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="magazine"
                  >
                    <i className="ri-book-read-fill"></i>
                    <span data-key="t-detached">{t("magazine.magazine")}</span>
                  </a>
                  <div className="collapse menu-dropdown" id="magazine">
                    <ul className="nav nav-sm flex-column">
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/magazine"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/magazine" && "active"
                        }`}
                      >
                        <span data-key="t-detached">
                          {t("global.overview")}
                        </span>
                      </Link>
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/magazine/create"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/magazine/create" &&
                          "active"
                        }`}
                      >
                        <span data-key="t-detached">{t("global.add")}</span>
                      </Link>
                      {checkPermission("magazine_category") && (
                        <Link
                          data-key="t-level-1.2"
                          to="/admin/magazine-category"
                          className={`nav-link menu-link  ${
                            location?.pathname === "/admin/magazine-category" &&
                            "active"
                          }`}
                        >
                          <span data-key="t-detached">
                            {t("table_headers.category")}
                          </span>
                        </Link>
                      )}
                      <Link
                          data-key="t-level-1.2"
                          to="/admin/magazine/settings"
                          className={`nav-link menu-link  ${
                              location?.pathname === "/admin/magazine/settings" &&
                              "active"
                          }`}
                      >
                          <span data-key="t-detached">
                            {t("settings.settings")}
                          </span>
                      </Link>
                    </ul>
                  </div>
                </li>
              )}

              {checkPermission("newsletter") && (
                <li className="nav-item">
                  <a
                    className="nav-link menu-link"
                    href="#newsletter"
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="newsletter"
                  >
                    <i className="ri-mail-send-fill"></i>
                    <span data-key="t-detached">
                      {t("newsletter.newsletter")}
                    </span>
                  </a>
                  <div className="collapse menu-dropdown" id="newsletter">
                    <ul className="nav nav-sm flex-column">
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/newsletter"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/newsletter" && "active"
                        }`}
                      >
                        <span data-key="t-detached">
                          {t("newsletter.create_title")}
                        </span>
                      </Link>

                      <Link
                        data-key="t-level-1.2"
                        to="/admin/newsletter-email/create"
                        className={`nav-link menu-link  ${
                          location?.pathname ===
                            "/admin/newsletter-email/create" && "active"
                        }`}
                      >
                        <span data-key="t-detached">
                          {t("global.email_create")}
                        </span>
                      </Link>

                      <Link
                        data-key="t-level-1.2"
                        to="/admin/newsletters/list"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/newsletters/list" &&
                          "active"
                        }`}
                      >
                        <span data-key="t-detached">
                          {t("newsletter.sent_newsletter")}
                        </span>
                      </Link>
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/newsletter-email-test"
                        className={`nav-link menu-link  ${
                          location?.pathname ===
                            "/admin/newsletter-email-test" && "active"
                        }`}
                      >
                        <span data-key="t-detached">
                          {t("global.email_create_test")}
                        </span>
                      </Link>

                      <Link
                        data-key="t-level-1.2"
                        to="/admin/newsletter-email/registered"
                        className={`nav-link menu-link  ${
                          location?.pathname ===
                            "/admin/newsletter-email/registered" && "active"
                        }`}
                      >
                        <span data-key="t-detached">
                          {t("global.registered")}
                        </span>
                      </Link>
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/newsletter-email/deregistered"
                        className={`nav-link menu-link  ${
                          location?.pathname ===
                            "/admin/newsletter-email/deregistered" && "active"
                        }`}
                      >
                        <span data-key="t-detached">
                          {t("global.deregistered")}
                        </span>
                      </Link>
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/newsletter-settings"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/newsletter-settings" &&
                          "active"
                        }`}
                      >
                        <span data-key="t-detached">
                          {t("settings.settings")}
                        </span>
                      </Link>
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/newsletter-export"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/newsletter-export" &&
                          "active"
                        }`}
                      >
                        <span data-key="t-detached">{t("global.export")}</span>
                      </Link>
                    </ul>
                  </div>
                </li>
              )}
              {/* green_pages permission */}
              {checkPermission("green_pages") && (
                <li className="nav-item">
                  <a
                    className="nav-link menu-link"
                    href="#green_pages"
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="green_pages"
                  >
                    <i className="ri-dvd-fill"></i>
                    <span data-key="t-detached">
                      {t("green_pages.green_pages")}
                    </span>
                  </a>
                  <div className="collapse menu-dropdown" id="green_pages">
                    <ul className="nav nav-sm flex-column">
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/green-pages"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/green-pages" &&
                          "active"
                        }`}
                      >
                        <span data-key="t-detached">
                          {t("global.overview")}
                        </span>
                      </Link>
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/green-pages/create"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/green-pages/create" &&
                          "active"
                        }`}
                      >
                        <span data-key="t-detached">{t("global.add")}</span>
                      </Link>
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/green-pages/category"
                        className={`nav-link menu-link ${
                          location?.pathname?.includes(
                            "/admin/green-pages/category"
                          ) && "active"
                        }`}
                      >
                        <span data-key="t-detached">
                          {t("table_headers.category")}
                        </span>
                      </Link>
                    </ul>
                  </div>
                </li>
              )}
              {/* team permission */}
              {checkPermission("team") && (
                <li className="nav-item">
                  <a
                    className="nav-link menu-link"
                    href="#team"
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="team"
                  >
                    <i className="ri-team-fill"></i>
                    <span data-key="t-detached">{t("team.team")}</span>
                  </a>
                  <div className="collapse menu-dropdown" id="team">
                    <ul className="nav nav-sm flex-column">
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/team"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/download" && "active"
                        }`}
                      >
                        <span data-key="t-detached">
                          {t("global.overview")}
                        </span>
                      </Link>
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/team/create"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/team/create" &&
                          "active"
                        }`}
                      >
                        <span data-key="t-detached">{t("global.add")}</span>
                      </Link>
                    </ul>
                  </div>
                </li>
              )}
              {checkPermission("event") && (
                <li className="nav-item">
                  <a
                    className="nav-link menu-link"
                    href="#event"
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="event"
                  >
                    <i className="ri-calendar-fill" />
                    <span data-key="t-detached">{t("event.event")}</span>
                  </a>
                  <div className="collapse menu-dropdown" id="event">
                    <ul className="nav nav-sm flex-column">
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/event"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/event" && "active"
                        }`}
                      >
                        <span data-key="t-detached">
                          {t("global.overview")}
                        </span>
                      </Link>
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/event/create"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/event/create" &&
                          "active"
                        }`}
                      >
                        <span data-key="t-detached">{t("global.add")}</span>
                      </Link>
                      {checkPermission("event_category") && (
                        <Link
                          data-key="t-level-1.2"
                          to="/admin/event-category"
                          className={`nav-link menu-link ${
                            location?.pathname?.includes(
                              "/admin/event-category"
                            ) && "active"
                          }`}
                        >
                          <span data-key="t-detached">
                            {t("table_headers.category")}
                          </span>
                        </Link>
                      )}
                    </ul>
                  </div>
                </li>
              )}
              {checkPermission("download") && (
                <li className="nav-item">
                  <a
                    className="nav-link menu-link"
                    href="#download"
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="download"
                  >
                    <i className="ri-download-line"></i>
                    <span data-key="t-detached">{t("download.download")}</span>
                  </a>
                  <div className="collapse menu-dropdown" id="download">
                    <ul className="nav nav-sm flex-column">
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/download"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/download" && "active"
                        }`}
                      >
                        <span data-key="t-detached">
                          {t("global.overview")}
                        </span>
                      </Link>
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/download/create"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/download/create" &&
                          "active"
                        }`}
                      >
                        <span data-key="t-detached">{t("global.add")}</span>
                      </Link>
                      {checkPermission("download_category") && (
                        <Link
                          data-key="t-level-1.2"
                          to="/admin/download-category"
                          className={`nav-link menu-link ${
                            getPathName(location.pathname) ===
                              "download-category" && "active"
                          }`}
                        >
                          <span data-key="t-detached">
                            {t("table_headers.category")}
                          </span>
                        </Link>
                      )}
                    </ul>
                  </div>
                </li>
              )}
              {checkPermission("gallery") && (
                <li className="nav-item">
                  <a
                    className="nav-link menu-link"
                    href="#album"
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="album"
                  >
                    <i className="ri-image-line"></i>
                    <span data-key="t-detached">
                      {t("album.photo_gallery")}
                    </span>
                  </a>
                  <div className="collapse menu-dropdown" id="album">
                    <ul className="nav nav-sm flex-column">
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/gallery"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/gallery" && "active"
                        }`}
                      >
                        <span data-key="t-detached">
                          {t("global.overview")}
                        </span>
                      </Link>
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/gallery/create"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/gallery/create" &&
                          "active"
                        }`}
                      >
                        <span data-key="t-detached">{t("global.add")}</span>
                      </Link>

                      <Link
                        data-key="t-level-1.2"
                        to="/admin/album"
                        className={`nav-link menu-link ${
                          location.pathname === "/admin/album" && "active"
                        }`}
                      >
                        <span data-key="t-detached">{t("album.album")}</span>
                      </Link>
                      <Link
                          data-key="t-level-1.2"
                          to="/admin/gallery-settings"
                          className={`nav-link menu-link  ${
                              location?.pathname === "/admin/gallery-settings" &&
                              "active"
                          }`}
                      >
                        <span data-key="t-detached">{t("settings.settings")}</span>
                      </Link>
                    </ul>
                  </div>
                </li>
              )}
              {/* video_gallery permission */}
              {checkPermission("video_gallery") && (
                <li className="nav-item">
                  <a
                    className="nav-link menu-link"
                    href="#video_gallery"
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="video_gallery"
                  >
                    <i className="ri-video-line"></i>
                    <span data-key="t-detached">
                      {t("video_gallery.video_gallery")}
                    </span>
                  </a>
                  <div className="collapse menu-dropdown" id="video_gallery">
                    <ul className="nav nav-sm flex-column">
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/video-gallery"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/video-gallery" &&
                          "active"
                        }`}
                      >
                        <span data-key="t-detached">
                          {t("global.overview")}
                        </span>
                      </Link>
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/gallery/video-create"
                        className={`nav-link menu-link  ${
                          location?.pathname ===
                            "/admin/gallery/video-create" && "active"
                        }`}
                      >
                        <span data-key="t-detached">{t("global.add")}</span>
                      </Link>

                      {/* <Link
                        data-key="t-level-1.2"
                        to="/admin/album"
                        className={`nav-link menu-link ${
                          location.pathname === "/admin/album" && "active"
                        }`}
                      >
                        <span data-key="t-detached">{t("album.album")}</span>
                      </Link> */}
                    </ul>
                  </div>
                </li>
              )}
              {/* market permission */}
              {checkPermission("market") && (
                <li className="nav-item">
                  <a
                    className="nav-link menu-link"
                    href="#market"
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="market"
                  >
                    <i className="ri-store-fill"></i>
                    <span data-key="t-detached">{t("market.market")}</span>
                  </a>
                  <div className="collapse menu-dropdown" id="market">
                    <ul className="nav nav-sm flex-column">
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/market"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/market" && "active"
                        }`}
                      >
                        <span data-key="t-detached">
                          {t("global.overview")}
                        </span>
                      </Link>
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/market/create"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/market/create" &&
                          "active"
                        }`}
                      >
                        <span data-key="t-detached">{t("global.add")}</span>
                      </Link>
                    </ul>
                  </div>
                </li>
              )}
              {checkPermission("fleet") && (
                <li className="nav-item">
                  <a
                    className="nav-link menu-link"
                    href="#fleet"
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="fleet"
                  >
                    <i className="ri-text"></i>
                    <span data-key="t-detached">{t("fleet.fleet")}</span>
                  </a>
                  <div className="collapse menu-dropdown" id="fleet">
                    <ul className="nav nav-sm flex-column">
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/fleet"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/download" && "active"
                        }`}
                      >
                        <span data-key="t-detached">
                          {t("global.overview")}
                        </span>
                      </Link>
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/fleet/create"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/fleet/create" &&
                          "active"
                        }`}
                      >
                        <span data-key="t-detached">{t("global.add")}</span>
                      </Link>
                      {/* {checkPermission("fleet_category") && (
                        <Link
                          data-key="t-level-1.2"
                          to="/admin/download-category"
                          className={`nav-link menu-link ${
                            getPathName(location.pathname) ===
                              "download-category" && "active"
                          }`}
                        >
                          <span data-key="t-detached">
                            {t("table_headers.category")}
                          </span>
                        </Link>
                      )} */}
                    </ul>
                  </div>
                </li>
              )}
              {/* {checkPermission("subscription") && (
                <li className="nav-item">
                  <Link
                    to="/admin/education-continuing"
                    className={`nav-link menu-link  ${
                      location?.pathname === "/admin/education-continuing" &&
                      "active"
                    }`}
                  >
                    <i className="ri-book-fill"></i>
                    <span data-key="t-detached">
                      {t("side_bar.continuing_education")}
                    </span>
                  </Link>
                </li>
              )} */}
              {/* {checkPermission("newsletter") && (
                <li className="nav-item">
                  <a
                    className="nav-link menu-link"
                    href="#education"
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="education"
                  >
                    <i className="ri-book-fill"></i>
                    <span data-key="t-detached">
                      {t("side_bar.continuing_education")}
                    </span>
                  </a>
                  <div className="collapse menu-dropdown" id="education">
                    <ul className="nav nav-sm flex-column">
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/education-continuing"
                        className={`nav-link menu-link  ${
                          location?.pathname ===
                            "/admin/education-continuing" && "active"
                        }`}
                      >
                        <span data-key="t-detached">
                          {t("global.overview")}
                        </span>
                      </Link>
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/education-continuing/create"
                        className={`nav-link menu-link  ${
                          location?.pathname ===
                            "/admin/education-continuing/create" && "active"
                        }`}
                      >
                        <span data-key="t-detached">{t("global.add")}</span>
                      </Link>
                    </ul>
                  </div>
                </li>
              )} */}
              {/* alternativeDrives */}
              {checkPermission("alternative_drives") && (
                <li className="nav-item">
                  <a
                    className="nav-link menu-link"
                    href="#alternativeDrives"
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="alternativeDrives"
                  >
                    <i className="ri-leaf-fill"></i>
                    <span data-key="t-detached">
                      {t("alternative_drives.alternative_drives")}
                    </span>
                  </a>
                  <div
                    className="collapse menu-dropdown"
                    id="alternativeDrives"
                  >
                    <ul className="nav nav-sm flex-column">
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/alternative-drives"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/alternative-drives" &&
                          "active"
                        }`}
                      >
                        <span data-key="t-detached">
                          {t("global.overview")}
                        </span>
                      </Link>
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/alternative-drives/create"
                        className={`nav-link menu-link  ${
                          location?.pathname ===
                            "/admin/alternative-drives/create" && "active"
                        }`}
                      >
                        <span data-key="t-detached">{t("global.add")}</span>
                      </Link>
                          <Link
                            data-key="t-level-1.2"
                            to="/admin/alternative-drives-category"
                            className={`nav-link menu-link ${
                              location?.pathname ===
                                "/admin/alternative-drives-category" && "active"
                            }`}
                          >
                            <span data-key="t-detached">
                              {t("table_headers.category")}
                            </span>
                          </Link>
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/alternative-drives-settings"
                        className={`nav-link menu-link  ${
                          location?.pathname ===
                            "/admin/alternative-drives-settings" && "active"
                        }`}
                      >
                        <span data-key="t-detached">
                          {t("settings.settings")}
                        </span>
                      </Link>
                    </ul>
                  </div>
                </li>
              )}
              {/* {checkPermission("newsletter") && (
                <li className="nav-item">
                  <a
                    className="nav-link menu-link"
                    href="#product"
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="product"
                  >
                    <i className="ri-gift-2-fill"></i>
                    <span data-key="t-detached">{t("product.product")}</span>
                  </a>
                  <div className="collapse menu-dropdown" id="product">
                    <ul className="nav nav-sm flex-column">
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/product"
                        className={`nav-link menu-link ${
                          location?.pathname === "/admin/product" && "active"
                        }`}
                      >
                        <span data-key="t-detached">
                          {t("global.overview")}
                        </span>
                      </Link>
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/product/create"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/product/create" &&
                          "active"
                        }`}
                      >
                        <span data-key="t-detached">{t("global.add")}</span>
                      </Link>
                      {
                        // checkPermission("event_summary_category")
                        true && (
                          <Link
                            data-key="t-level-1.2"
                            to="/admin/product-category"
                            className={`nav-link menu-link ${
                              location?.pathname ===
                                "/admin/product-category" && "active"
                            }`}
                          >
                            <span data-key="t-detached">
                              {t("table_headers.category")}
                            </span>
                          </Link>
                        )
                      }
                    </ul>
                  </div>
                </li>
              )} */}
              {checkPermission("menu") && (
                <li className="nav-item ">
                  <a
                    className="nav-link menu-link"
                    href="#menu"
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="menu"
                  >
                    <i className="ri-book-read-fill"></i>
                    <span data-key="t-detached">{t("menu.menu")}</span>
                  </a>
                  <div className="collapse menu-dropdown" id="menu">
                    <ul className="nav nav-sm flex-column">
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/menu"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/menu" && "active"
                        }`}
                      >
                        <span data-key="t-detached">
                          {t("global.overview")}
                        </span>
                      </Link>
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/menu/create"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/menu/create" &&
                          "active"
                        }`}
                      >
                        <span data-key="t-detached">{t("global.add")}</span>
                      </Link>

                      <Link
                        to="/admin/menu-position"
                        className={`nav-link menu-link ${
                          getPathName(location.pathname) === "menu-position" &&
                          "active"
                        }`}
                      >
                        <span data-key="t-detached">
                          {t("menu_position.menu_position")}
                        </span>
                      </Link>
                    </ul>
                  </div>
                </li>
              )}
              {checkPermission("subscription") && (
                <li className="nav-item">
                  <Link
                    to="/admin/subscription"
                    className={`nav-link menu-link ${
                      getPathName(location.pathname) === "subscription" &&
                      "active"
                    }`}
                  >
                    <i className="ri-money-dollar-box-fill"></i>
                    <span data-key="t-detached">
                      {t("subscription.subscription")}
                    </span>
                  </Link>
                </li>
              )}
              {checkPermission("mediadaten") && (
                <li className="nav-item">
                  <Link
                    to="/admin/mediadaten"
                    className={`nav-link menu-link ${
                      getPathName(location.pathname) === "mediadaten" &&
                      "active"
                    }`}
                  >
                    <i className="ri-file-pdf-fill"></i>
                    <span data-key="t-detached">
                      {t("mediadaten.mediadaten")}
                    </span>
                  </Link>
                </li>
              )}
              {checkPermission("page") && (
                <li className="nav-item">
                  <a
                    className="nav-link menu-link"
                    href="#page"
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="page"
                  >
                    <i className="ri-article-line"></i>
                    <span data-key="t-detached">{t("page.page")}</span>
                  </a>
                  <div className="collapse menu-dropdown" id="page">
                    <ul className="nav nav-sm flex-column">
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/page"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/page" && "active"
                        }`}
                      >
                        <span data-key="t-detached">
                          {t("global.overview")}
                        </span>
                      </Link>
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/page/create"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/page/create" &&
                          "active"
                        }`}
                      >
                        <span data-key="t-detached">{t("global.add")}</span>
                      </Link>
                    </ul>
                  </div>
                </li>
              )}
              {checkPermission("company_details") && (
                <li className="nav-item">
                  <Link
                    to="/admin/company"
                    className={`nav-link menu-link ${
                      getPathName(location.pathname) === "company" && "active"
                    }`}
                  >
                    <i className="ri-building-2-fill"></i>
                    <span data-key="t-detached">
                      {t("settings.company_details")}
                    </span>
                  </Link>
                </li>
              )}
              {checkPermission("user") && (
                <li className="nav-item">
                  <a
                    className="nav-link menu-link"
                    href="#user"
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="user"
                  >
                    <i className="ri-calendar-fill" />
                    <span data-key="t-detached">{t("user.user")}</span>
                  </a>
                  <div className="collapse menu-dropdown" id="user">
                    <ul className="nav nav-sm flex-column">
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/user"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/user" && "active"
                        }`}
                      >
                        <span data-key="t-detached">
                          {t("global.overview")}
                        </span>
                      </Link>
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/user/create"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/user/create" &&
                          "active"
                        }`}
                      >
                        <span data-key="t-detached">{t("global.add")}</span>
                      </Link>
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/user/active"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/user/active" &&
                          "active"
                        }`}
                      >
                        <span data-key="t-detached">{t("global.active")}</span>
                      </Link>{" "}
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/user/pending"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/user/pending" &&
                          "active"
                        }`}
                      >
                        <span data-key="t-detached">{t("global.pending")}</span>
                      </Link>{" "}
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/user/inactive"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/user/inactive" &&
                          "active"
                        }`}
                      >
                        <span data-key="t-detached">
                          {t("global.inactive")}
                        </span>
                      </Link>
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/user/export"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/user/export" &&
                          "active"
                        }`}
                      >
                        <span data-key="t-detached">{t("global.export")}</span>
                      </Link>
                    </ul>
                  </div>
                </li>
                // <li className="nav-item">
                //   <Link
                //     to="/admin/user"
                //     className={`nav-link menu-link ${
                //       getPathName(location.pathname) === "user" && "active"
                //     }`}
                //   >
                //     <i className="ri-user-search-line"></i>
                //     <span data-key="t-detached">{t("user.user")}</span>
                //   </Link>
                // </li>
              )}

              {checkPermission("event_summary") && (
                <li className="nav-item">
                  <a
                    className="nav-link menu-link"
                    href="#event-summary"
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="event-summary"
                  >
                    <i className="ri-coupon-5-fill"></i>
                    <span data-key="t-detached">
                      {t("admin_event_summary.event_summary")}
                    </span>
                  </a>
                  <div className="collapse menu-dropdown" id="event-summary">
                    <ul className="nav nav-sm flex-column">
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/event-summary"
                        className={`nav-link menu-link ${
                          location?.pathname === "admin/event-summary" &&
                          "active"
                        }`}
                      >
                        <span data-key="t-detached">
                          {t("global.overview")}
                        </span>
                      </Link>
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/event-summary/create"
                        className={`nav-link menu-link  ${
                          location?.pathname ===
                            "/admin/event-summary/create" && "active"
                        }`}
                      >
                        <span data-key="t-detached">{t("global.add")}</span>
                      </Link>
                      {checkPermission("event_summary_category") && (
                        <Link
                          data-key="t-level-1.2"
                          to="/admin/event-summary/category"
                          className={`nav-link menu-link ${
                            location?.pathname ===
                              "/admin/event-summary/category" && "active"
                          }`}
                        >
                          <span data-key="t-detached">
                            {t("table_headers.category")}
                          </span>
                        </Link>
                      )}
                    </ul>
                  </div>
                </li>
              )}
              {/* banner permission */}
              {/* banner */}
              {checkPermission("banner") && (
                <li className="nav-item">
                  <a
                    className="nav-link menu-link"
                    href="#banner"
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded="false"
                    aria-controls="banner"
                  >
                    <i className="ri-picture-in-picture-fill"></i>
                    <span data-key="t-detached">{t("banner.banner")}</span>
                  </a>
                  <div className="collapse menu-dropdown" id="banner">
                    <ul className="nav nav-sm flex-column">
                      <Link
                        data-key="t-level-1.2"
                        to="/admin/banner"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/banner" && "active"
                        }`}
                      >
                        <span data-key="t-detached">
                          {t("global.overview")}
                        </span>
                      </Link>

                      {/* <Link
                        data-key="t-level-1.2"
                        to="/admin/magazine/create"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/magazine/create" &&
                          "active"
                        }`}
                      >
                        <span data-key="t-detached">{t("global.add")}</span>
                      </Link> */}

                      <Link
                        data-key="t-level-1.2"
                        to="/admin/banner-zone"
                        className={`nav-link menu-link  ${
                          location?.pathname === "/admin/banner-zone" &&
                          "active"
                        }`}
                      >
                        <span data-key="t-detached">
                          {t("table_headers.zone")}
                        </span>
                      </Link>
                    </ul>
                  </div>
                </li>
              )}
              {/* slider permission */}
              {checkPermission("slider") && (
                <li className="nav-item">
                  <Link
                    to="/admin/home-settings"
                    className={`nav-link menu-link ${
                      getPathName(location.pathname) === "home-settings" &&
                      "active"
                    }`}
                  >
                    <i className="ri-slideshow-4-fill"></i>
                    <span data-key="t-detached">{t("side_bar.slider")}</span>
                  </Link>
                </li>
              )}
              {checkPermission("speakers") && (
                  <li className="nav-item">
                    <a
                        className="nav-link menu-link"
                        href="#speakers"
                        data-bs-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                        aria-controls="cars"
                    >
                      <i className="bi bi-mic-fill"></i>
                      <span data-key="t-detached">{t("speakers.speakers")}</span>
                    </a>
                    <div className="collapse menu-dropdown" id="speakers">
                      <ul className="nav nav-sm flex-column">
                        <Link
                            data-key="t-level-1.2"
                            to="/admin/speaker"
                            className={`nav-link menu-link  ${
                                location?.pathname === "/admin/speaker" && "active"
                            }`}
                        >
                        <span data-key="t-detached">
                          {t("global.overview")}
                        </span>
                        </Link>
                        <Link
                            data-key="t-level-1.2"
                            to="/admin/speaker/create"
                            className={`nav-link menu-link  ${
                                location?.pathname === "/admin/speaker/create" &&
                                "active"
                            }`}
                        >
                          <span data-key="t-detached">{t("global.add")}</span>
                        </Link>
                        <Link
                            data-key="t-level-1.2"
                            to="/admin/speaker-settings"
                            className={`nav-link menu-link  ${
                                location?.pathname === "/admin/speaker-settings" &&
                                "active"
                            }`}
                        >
                          <span data-key="t-detached">{t("settings.settings")}</span>
                        </Link>
                        <Link
                            data-key="t-level-1.2"
                            to="/admin/speaker-specification"
                            className={`nav-link menu-link  ${
                                location?.pathname === "/admin/speaker-specification" &&
                                "active"
                            }`}
                        >
                          <span data-key="t-detached">{t("global.specification")}</span>
                        </Link>
                      </ul>
                    </div>
                  </li>
              )}
              {checkPermission("sections") && (
                  <li className="nav-item">
                    <a
                        className="nav-link menu-link"
                        href="#sections"
                        data-bs-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                        aria-controls="sections"
                    >
                      <i className="bi bi-filter-square-fill"></i>
                      <span data-key="t-detached">{t("sections.sections")}</span>
                    </a>
                    <div className="collapse menu-dropdown" id="sections">
                      <ul className="nav nav-sm flex-column">
                        <Link
                            data-key="t-level-1.2"
                            to="/admin/sections"
                            className={`nav-link menu-link  ${
                                location?.pathname === "/admin/sections" && "active"
                            }`}
                        >
                        <span data-key="t-detached">
                          {t("global.overview")}
                        </span>
                        </Link>
                        <Link
                            data-key="t-level-1.2"
                            to="/admin/section/create"
                            className={`nav-link menu-link  ${
                                location?.pathname === "/admin/section/create" &&
                                "active"
                            }`}
                        >
                          <span data-key="t-detached">{t("global.add")}</span>
                        </Link>
                      </ul>
                    </div>
                  </li>
              )}
              {checkPermission("program") && (
                  <li className="nav-item">
                    <Link
                        to="/admin/program"
                        className={`nav-link menu-link ${
                            getPathName(location.pathname) === "program" && "active"
                        }`}
                    >
                      <i className="bi bi-calendar3-range-fill"></i>
                      <span data-key="t-detached">{t("program.program")}</span>
                    </Link>
                  </li>
              )}

              {checkPermission("target_group") && (
                  <li className="nav-item">
                    <Link
                        to="/admin/target-group"
                        className={`nav-link menu-link ${
                            getPathName(location.pathname) === "target-group" && "active"
                        }`}
                    >
                      <i className="bi bi-bullseye"></i>
                      <span data-key="t-detached">{t("target_group.target_group")}</span>
                    </Link>
                  </li>
              )}

              {checkPermission("template") && (
                  <li className="nav-item">
                    <a
                        className="nav-link menu-link"
                        href="#template"
                        data-bs-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                        aria-controls="magazine"
                    >
                      <i className="bi bi-columns-gap"></i>
                      <span data-key="t-detached">{t("template.template")}</span>
                    </a>
                    <div className="collapse menu-dropdown" id="template">
                      <ul className="nav nav-sm flex-column">
                        <Link
                            data-key="t-level-1.2"
                            to="/admin/template"
                            className={`nav-link menu-link  ${
                                location?.pathname === "/admin/template" && "active"
                            }`}
                        >
                        <span data-key="t-detached">
                          {t("global.overview")}
                        </span>
                        </Link>
                        <Link
                            data-key="t-level-1.2"
                            to="/admin/template/create"
                            className={`nav-link menu-link  ${
                                location?.pathname === "/admin/template/create" &&
                                "active"
                            }`}
                        >
                          <span data-key="t-detached">{t("global.add")}</span>
                        </Link>
                        <Link
                            data-key="t-level-1.2"
                            to="/admin/template-settings"
                            className={`nav-link menu-link  ${
                                location?.pathname === "/admin/template-settings" &&
                                "active"
                            }`}
                        >
                          <span data-key="t-detached">
                            {t("settings.settings")}
                          </span>
                        </Link>
                      </ul>
                    </div>
                  </li>
              )}

              <li>
                <hr />
              </li>
              {/*{getAuth?.roles?.includes("ROLE_SUPER_ADMIN") && (*/}
              {/*  <>*/}
              {/*<hr />*/}
              {/*<li className="menu-title">*/}
              {/*  <span data-key="t-menu"> {t("side_bar.super_admin")}</span>*/}
              {/*</li>*/}
              {/*<li className="nav-item">*/}
              {/*  <Link*/}
              {/*    to="/admin/admin"*/}
              {/*    className={`nav-link menu-link ${*/}
              {/*      getPathName(location.pathname) === "settings" &&*/}
              {/*      "active"*/}
              {/*    }`}*/}
              {/*  >*/}
              {/*    <i className="ri-user-settings-fill"></i>*/}
              {/*    <span data-key="t-detached">{t("admin.admin")}</span>*/}
              {/*  </Link>*/}
              {/*</li>*/}
              {/*<li className="nav-item mb-4">*/}
              {/*  <Link*/}
              {/*    to="/admin/settings"*/}
              {/*    className={`nav-link menu-link ${*/}
              {/*      getPathName(location.pathname) === "settings" &&*/}
              {/*      "active"*/}
              {/*    }`}*/}
              {/*  >*/}
              {/*    <i className="ri-settings-5-line"></i>*/}
              {/*    <span data-key="t-detached">*/}
              {/*      {t("settings.settings")}*/}
              {/*    </span>*/}
              {/*  </Link>*/}
              {/*</li>{" "}*/}
              {/*</>*/}
              {/*)}*/}

              {/* <li className="nav-item">
                <a
                  className="nav-link menu-link"
                  href="#sidebarMultilevel"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarMultilevel"
                >
                  <i className="bi bi-share"></i>{" "}
                  <span data-key="t-multi-level">Multi Level</span>
                </a>
                <div className="collapse menu-dropdown" id="sidebarMultilevel">
                  <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <a href="#" className="nav-link" data-key="t-level-1.1">
                        Level 1.1
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#sidebarAccount"
                        className="nav-link"
                        data-bs-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                        aria-controls="sidebarAccount"
                        data-key="t-level-1.2"
                      >
                        Level 1.2
                      </a>
                      <div
                        className="collapse menu-dropdown"
                        id="sidebarAccount"
                      >
                        <ul className="nav nav-sm flex-column">
                          <li className="nav-item">
                            <a
                              href="#"
                              className="nav-link"
                              data-key="t-level-2.1"
                            >
                              Level 2.1{" "}
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              href="#sidebarCrm"
                              className="nav-link"
                              data-bs-toggle="collapse"
                              role="button"
                              aria-expanded="false"
                              aria-controls="sidebarCrm"
                              data-key="t-level-2.2"
                            >
                              Level 2.2
                            </a>
                            <div
                              className="collapse menu-dropdown"
                              id="sidebarCrm"
                            >
                              <ul className="nav nav-sm flex-column">
                                <li className="nav-item">
                                  <a
                                    href="#"
                                    className="nav-link"
                                    data-key="t-level-3.1"
                                  >
                                    Level 3.1
                                  </a>
                                </li>
                                <li className="nav-item">
                                  <a
                                    href="#"
                                    className="nav-link"
                                    data-key="t-level-3.2"
                                  >
                                    Level 3.2
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </li> */}
            </ul>
          </div>
          {/* <!-- Sidebar --> */}
        </div>
        {/* <div className="sidebar-background"></div> */}
      </div>
    </>
  );
};
