import {Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import React, {useState} from "react";
import {Pagination} from "../../../shared/paginations/Paginations";
import {ConfirmDelete} from "../../../shared/components/ConfirmDelete";
import {AdminSubscriberAutoInformationService} from "../service";
import {ErrorToast, SuccessToast} from "../../../shared/toasters/toasters";
import {Loader} from "../../../shared/components/Loader";
import {EmptyState} from "../../../shared/components/EmptyState";
import {useTranslation} from "react-i18next";

export const List = ({
                         list,
                         setPagination,
                         totalPages,
                         page,
                         handlePages,
                         editHandler,
                     }: any) => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [selectedPrompt, setselectedPrompt] = useState<any>();
    const deleteHandler = () => {
        selectedPrompt?.id &&
        AdminSubscriberAutoInformationService.delete(selectedPrompt?.id)
            .then((response) => {
                if (response?.status === "success") {
                    setPagination((prev: any)=>({...prev, updatedAt: new Date()?.getTime()}))
                    SuccessToast(t(`subscribers.successfully_deleted`));
                }
            })
            .catch((error) => ErrorToast(error));
    };

    return (
        <div className="card">
            <div className="card-header pb-0">
                <div className="d-flex justify-content-between">
                    <h4 className="card-title mg-b-0">{t("subscribers.listOfSubscribers")}</h4>
                </div>
            </div>
            <div className="card-body">
                {list === undefined ? (
                    <Loader />
                ) : list.length === 0 ? (
                    <EmptyState />
                ) : (
                    <div className="table-responsive">
                        <Table className="align-middle table-nowrap mb-0">
                            <thead className="table-light text-center">
                            <tr>
                                <th>{t(`global.id`)}</th>
                                <th>{t(`global.name`)}</th>
                                <th>{t(`global.email`)}</th>
                                <th>{t(`global.aboCode`)}</th>
                                <th>{t(`global.active`)}</th>
                                <th>{t(`table_headers.actions`)}</th>
                            </tr>
                            </thead>
                            <tbody className="text-center">
                            {list?.map((prompt: any) => {
                                return (
                                    <tr key={prompt?.id} className="">
                                        <td>
                                            <Link to="#" className="fw-medium">
                                                #{prompt?.id}
                                            </Link>
                                        </td>
                                        <td>{prompt?.name ?? "-"}</td>
                                        <td>{prompt?.email ?? "-"}</td>
                                        <td>{prompt?.aboCode ?? "-"}</td>
                                        <td
                                            className={`text-${
                                                prompt?.active ? "success" : "danger"
                                            }`}
                                        >
                                            {prompt?.active ? (
                                                <span>
                                                    <i className="ri-checkbox-circle-line fs-17 align-middle me-1"/>
                                                    {t(`global.active`)}
                                                </span>
                                            ) : (
                                                <span>
                                                    <i className="ri-close-circle-line fs-17 align-middle me-1"/>
                                                    {t(`global.inactive`)}
                                                </span>
                                            )}
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <button
                                                    className="btn btn-sm btn-warning d-flex align-items-center me-2"
                                                    onClick={() => editHandler(prompt?.id)}
                                                >
                                                    <i className="ri-edit-2-fill me-1" />
                                                    {t("global.edit")}
                                                </button>
                                                <button
                                                    className="btn btn-sm btn-danger d-flex align-items-center"
                                                    onClick={() => {
                                                        handleShow();
                                                        setselectedPrompt(prompt);
                                                    }}
                                                >
                                                    <i className="ri-delete-bin-fill me-1" />
                                                    {t("global.delete")}
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </Table>
                    </div>
                )}

                <div className="d-flex justify-content-end ">
                    {totalPages <= 1 ? (
                        ""
                    ) : (
                        <Pagination
                            page={page}
                            totalPages={totalPages}
                            handlePagination={handlePages}
                        />
                    )}
                </div>
                <ConfirmDelete
                    show={show}
                    setShow={setShow}
                    itemName={selectedPrompt?.email}
                    deleteHandler={deleteHandler}
                    product={selectedPrompt}
                    selectedProduct={selectedPrompt}
                />
            </div>
        </div>
    );
};
