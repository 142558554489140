import React, {useState} from "react";
import {Table} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {ConfirmDelete} from "../../../shared/components/ConfirmDelete";
import {EmptyState} from "../../../shared/components/EmptyState";
import {Loader} from "../../../shared/components/Loader";
import {INewsCategoryAutoInformation} from "../interface";

interface IProps {
    state: INewsCategoryAutoInformation[];
    editHandler: (id: number) => void;
    deleteHandler: () => void;
}

export const List = ({
                         state,
                         editHandler,
                         deleteHandler
                     }: IProps) => {
    const {t} = useTranslation();
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [selectedPrompt, setSelectedPrompt] = useState<INewsCategoryAutoInformation | null>(null);

    return (
        <div className="card">
            <div className="card-header pb-0">
                <div className="d-flex justify-content-between">
                    <h4 className="card-title mg-b-0">
                        {t("category.list_of_categories")}
                    </h4>
                </div>
            </div>
            <div className="card-body">
                {state === undefined ? (
                    <Loader/>
                ) : state?.length === 0 ? (
                    <EmptyState/>
                ) : (
                    <div className="table-responsive">
                        <Table className="align-middle table-nowrap mb-0">
                            <thead className="table-light">
                            <tr>
                                <th>{t(`global.id`)}</th>
                                <th>{t(`table_headers.title`)}</th>
                                <th>{t(`table_headers.active`)}</th>
                                <th className="text-center">
                                    {t(`table_headers.actions`)}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {state?.map((prompt: INewsCategoryAutoInformation) => {
                                return (
                                    <tr key={prompt?.id}>
                                        <td>
                                            <Link to="#" className="fw-medium">
                                                #{prompt?.id}
                                            </Link>
                                        </td>
                                        <td>{prompt?.title ?? "-"}</td>
                                        <td
                                            className={`text-${
                                                prompt?.active ? "success" : "danger"
                                            }`}
                                        >
                                            {prompt?.active ? (
                                                <span>
                                                    <i className="ri-checkbox-circle-line fs-17 align-middle me-1"/>
                                                    {t(`global.active`)}
                                                </span>
                                            ) : (
                                                <span>
                                                    <i className="ri-close-circle-line fs-17 align-middle me-1"/>
                                                    {t(`global.inactive`)}
                                                </span>
                                            )}
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <button
                                                    className="btn btn-sm btn-warning d-flex align-items-center me-2"
                                                    onClick={() => editHandler(prompt?.id as number)}
                                                >
                                                    <i className="ri-edit-2-fill me-1"/>
                                                    {t("global.edit")}
                                                </button>
                                                <button
                                                    className="btn btn-sm btn-danger d-flex align-items-center"
                                                    onClick={() => {
                                                        handleShow();
                                                        setSelectedPrompt(prompt);
                                                    }}
                                                >
                                                    <i className="ri-delete-bin-fill me-1"/>
                                                    {t("global.delete")}
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </Table>
                    </div>
                )}
                <ConfirmDelete
                    show={show}
                    setShow={setShow}
                    itemName={selectedPrompt?.title}
                    deleteHandler={deleteHandler}
                    product={selectedPrompt}
                    selectedProduct={selectedPrompt}
                />
            </div>
        </div>
    );
};
