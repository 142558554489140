import {IPagination} from "../../shared/interface";
import {requests} from "../../helpers/api.services";
import {EDITIONS_URL} from "../../helpers/api.routes";

export const AdminEditionsService = {
    getAll: (params: IPagination, active?: boolean) => requests.get(`${EDITIONS_URL}`, {...params, active}),
    getSingle: (id: number) => requests.get(`${EDITIONS_URL}/${id}`),
    create: (payload: FormData) => requests.post(`${EDITIONS_URL}`, payload, true),
    update: (payload: FormData, id: number) =>
        requests.post(`${EDITIONS_URL}/${id}`, payload, true),
    delete: (id: number) => requests.delete(`${EDITIONS_URL}/${id}`),
    updatePosition: (ids: number[]) => requests.patch(`${EDITIONS_URL}`, {ids}),
}