import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {ErrorToast, SuccessToast} from "../../shared/toasters/toasters";
import List from "./components/List";
import DraggableBootstrapTable from "../../shared/components/draggableList/Draggable";
import {Pagination} from "../../shared/paginations/Paginations";
import {ConfirmDelete} from "../../shared/components/ConfirmDelete";
import {IEditions} from "./interface";
import {AdminEditionsService} from "./service";

const AdminEditions = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [list, setList] = useState<IEditions[]>([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [selectedPrompt, setSelectedPrompt] = useState<IEditions | undefined>(undefined);
    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 10,
        term: "",
    });
    const handlePages = (updatePage: number) => {
        setPagination({...pagination, page: updatePage});
        setPage(updatePage);
    };
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);

    const deleteHandler = () => {
        selectedPrompt?.id &&
        AdminEditionsService.delete(selectedPrompt?.id)
            .then((response) => {
                if (response?.status === "success") {
                    setPagination(prev => ({...prev, updatedAt: new Date()?.getTime()}))
                    SuccessToast(t(`editions.successfully_deleted`));
                }
            })
            .catch((error) => ErrorToast(error));
    };
    const editHandler = (id: number): void => {
        navigate(`/admin/editions/update/${id}`);
    };
    const updatePosition = (newList: IEditions[]) => {
        const newListUpdated = newList?.map((item) => item.id) as number[];
        AdminEditionsService.updatePosition(newListUpdated);
    };

    useEffect(() => {
        AdminEditionsService.getAll(pagination)
            .then((response) => {
                const {data} = response;
                setList(data?.items);
                setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
            })
            .catch((error) => ErrorToast(error));
    }, [pagination]);
    return (
        <>
            <div className="col-12">
                <div className="card">
                    <div className="card-body d-flex align-items-center ">
                        <div className="d-flex align-items-center gap-3">
                            <div className="search-box">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="searchMemberList"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setPagination((prev) => ({
                                            ...prev,
                                            page: 1,
                                            term: e.target.value,
                                        }))
                                    }
                                    placeholder={t("global.search")}
                                />
                                <i className="ri-search-line search-icon"></i>
                            </div>
                        </div>
                        <button className="btn btn-primary ms-auto"
                                onClick={() => navigate("/admin/editions/create")}>
                            {t("global.add")}
                        </button>
                    </div>
                </div>
            </div>
            <div className="col-12">
                {page > 1 || pagination?.term ? (
                    <List list={list}
                          editHandler={editHandler}
                          setShow={setShow}
                          setSelectedPrompt={setSelectedPrompt}
                    />
                ) : (
                    <DraggableBootstrapTable
                        preview={true}
                        list={list!}
                        setList={setList}
                        headers={["id", "title", "date", "active"]}
                        title="editions"
                        onEdit={editHandler}
                        selectedPrompt={selectedPrompt}
                        setSelectedPrompt={setSelectedPrompt}
                        handleShow={handleShow}
                        show={show}
                        setShow={setShow}
                        deleteHandler={deleteHandler}
                        updatePosition={updatePosition}
                    />
                )}
                {page && (
                    <div className="d-flex justify-content-end">
                        {totalPages <= 1 ? (
                            ""
                        ) : (
                            <Pagination
                                page={page}
                                totalPages={totalPages}
                                handlePagination={handlePages}
                            />
                        )}
                    </div>
                )}
            </div>

            <ConfirmDelete
                show={show}
                setShow={setShow}
                itemName={selectedPrompt?.title}
                deleteHandler={deleteHandler}
                selectedProduct={selectedPrompt}
            />
        </>
    );
};

export default AdminEditions;