import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {ErrorToast} from "../../shared/toasters/toasters";
import {ISubscriberAutoInformation} from "./interface";
import {AdminSubscriberAutoInformationService} from "./service";
import {List} from "./components/List";

const AdminSubscriberAutoInformation = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [list, setList] = useState<ISubscriberAutoInformation[]>([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 10,
        term: "",
    });
    const handlePages = (updatePage: number) => {
        setPagination({...pagination, page: updatePage});
        setPage(updatePage);
    };
    const editHandler = (id: number): void => {
        navigate(`/admin/auto-information/subscriber/update/${id}`);
    };

    useEffect(() => {
        AdminSubscriberAutoInformationService.getAll(pagination)
            .then((response) => {
                const {data} = response;
                setList(data?.items);
                setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
            })
            .catch((error) => ErrorToast(error));
    }, [pagination]);
    return (
        <div className="col-12">
            <div className="card">
                <div className="card-body d-flex align-items-center ">
                    <div className="d-flex align-items-center gap-3">
                        <div className="search-box">
                            <input
                                type="text"
                                className="form-control"
                                id="searchMemberList"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setPagination((prev) => ({
                                        ...prev,
                                        page: 1,
                                        term: e.target.value,
                                    }))
                                }
                                placeholder={t("global.search")}
                            />
                            <i className="ri-search-line search-icon"></i>
                        </div>
                    </div>
                    <button className="btn btn-primary ms-auto"
                            onClick={() => navigate("/admin/auto-information/subscriber/create")}>
                        {t("global.add")}
                    </button>
                </div>
            </div>
            <List
                list={list}
                totalPages={totalPages}
                page={page}
                setPagination={setPagination}
                handlePages={handlePages}
                editHandler={editHandler}
            />

        </div>
    );
};

export default AdminSubscriberAutoInformation;