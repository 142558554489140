import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {ErrorToast, SuccessToast} from "../../shared/toasters/toasters";
import {Col, Row} from "react-bootstrap";
import {IFile, IInFocusSettings} from "./interface";
import {InFocusSettingsService} from "./service";
import {PICTURE_URL} from "../../helpers/api.routes";

const AdminInFocusSettings = () => {
    const [state, setState] = useState<IInFocusSettings | null>(null);
    const {t} = useTranslation();
    const [selectEditor, setSelectEditor] = useState<boolean>(false)
    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setState((prev) => ({...prev, [name]: value} as IInFocusSettings));
    };
    const switchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({...prev, [event.target.name]: event.target.checked}) as IInFocusSettings);
    };

    const fileHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({...prev, [event.target.name]: event.target.files?.[0]}) as IInFocusSettings);
    };
    const submitHandler = (event: React.FormEvent) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('body', JSON.stringify(state));
        !!(state?.image as File)?.size && formData.append('image', state?.image as File);

        InFocusSettingsService.update(formData)
            .then((response) => {
                setState(response?.data)
                SuccessToast(t(`toasts.successfully_created`));
            })
            .catch((error) => ErrorToast(error));
    };

    function removeElementsByClass(className: string) {
        const elements = document.getElementsByClassName(className);
        while (elements.length > 0) {
            elements && elements[0]?.parentNode?.removeChild(elements[0]);
        }
        setSelectEditor(false);
    }

    useEffect(() => {
        if (selectEditor) {
            removeElementsByClass(
                "jsplus_theme jsplus_ui_full_screen jsplus_ui_sidebar_widgets jsplus_show"
            );
        }
    }, [selectEditor]);

    useEffect(() => {
        InFocusSettingsService.get()
            .then((response) => {
                const {data} = response;
                setState(data);
            })
            .catch((error) => ErrorToast(error));
    }, []);


    return (
        <div className="card">
            <div className="card-body">
                <Row>
                    <Col md={5}>
                        <label htmlFor="title" className="required-field">
                            {t("global.title")}
                        </label>
                        <input
                            type="text"
                            name="title"
                            className="form-control"
                            required
                            onChange={(e) => changeHandler(e)}
                            value={state?.id && state?.title}
                        />
                    </Col>
                    <Col md={5}>
                        <label htmlFor="url" className="required-field">
                            {t("global.url")}
                        </label>
                        <input
                            type="text"
                            name="url"
                            className="form-control"
                            onChange={(e) => changeHandler(e)}
                            value={state?.id && state?.url}
                        />
                    </Col>
                    <Col md={2}>
                        <div className="form-check form-switch p-0">
                            <label
                                className="d-block text-start"
                                htmlFor="flexSwitchCheckDefault"
                            >
                                {t("global.hide")}
                            </label>
                            <div className="row">
                                <input
                                    className="form-check-input switch-custom ms-3 mt-2"
                                    type="checkbox"
                                    name="hide"
                                    checked={state?.hide}
                                    onChange={switchHandler}
                                    role="switch"
                                    id="flexSwitchCheckDefault"
                                />
                            </div>
                        </div>
                    </Col>
                    <Col md={12} className="my-3">
                        <div className="">
                            <label htmlFor="imageFile">{t("download.image")}</label>
                            <input
                                onChange={fileHandler}
                                name={'image'}
                                className="form-control"
                                type="file"
                                accept="image/*"
                                id="imageFile"
                            />
                        </div>
                        {(state?.image as IFile)?.path && (
                            <img
                                className="mt-3 rounded show-img-form"
                                alt="200x200"
                                src={PICTURE_URL + (state?.image as IFile)?.path}
                                data-holder-rendered="true"
                            />
                        )}
                    </Col>
                </Row>
                <Row>
                    <div className="col-12 mt-4 d-flex justify-content-end text-align-center">
                        <button
                            className="btn btn-primary"
                            onClick={submitHandler}
                        >
                            {t("global.save")}
                        </button>
                    </div>
                </Row>
            </div>
        </div>
    );
};

export default AdminInFocusSettings;