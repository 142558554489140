import React, {ChangeEvent, SetStateAction, useEffect, useState} from 'react';
import {Card, Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {INewsAutoInformation} from "../interface";
import {ErrorToast} from "../../../shared/toasters/toasters";
import {AdminNewsAutoInformationService} from "../service";
import {InitEditor} from "../../../shared/tinyMce/InitEditor";
import {AdminNewsCategoryAutoInformationService} from "../../newsCategoryAutoInformation/service";
import Select, {GroupBase} from "react-select";
import {INewsCategoryAutoInformation} from "../../newsCategoryAutoInformation/interface";
import {IEditions} from "../../editions/interface";
import {AdminEditionsService} from "../../editions/service";

interface IProps {
    state: INewsAutoInformation | null;
    setState: React.Dispatch<SetStateAction<INewsAutoInformation | null>>;
}

interface IDropdown {
    value: number;
    label: string;
}


const CreateForm = ({state, setState}: IProps) => {
    const {t} = useTranslation();
    const {id} = useParams();
    const navigate = useNavigate();
    const [categoryList, setCategoryList] = useState<GroupBase<INewsCategoryAutoInformation>[]>([]);
    const [editionList, setEditionList] = useState<GroupBase<IEditions>[]>([]);

    function handleChange(event: ChangeEvent<HTMLInputElement>, isNumber = false) {
        const {name, value, valueAsNumber} = event.target;
        setState((prev) => ({...prev, [name]: isNumber ? valueAsNumber : value}) as INewsAutoInformation);
    }

    const handleText = (text: string) => {
        setState((prev) => ({...prev, text}) as INewsAutoInformation);
    };
    const switchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({...prev, [e.target.name]: e.target.checked}) as INewsAutoInformation);
    };

    const categoriesHandler = (categoryId: number) => {
        setState((prev) => ({
            ...prev,
            category: categoryId,
        }));
    };

    const editionsHandler = (editionId: number) => {
        setState((prev) => ({
            ...prev,
            edition: editionId,
        }));
    };
    useEffect(() => {
        if (!!Number(id)) {
            AdminNewsAutoInformationService.getSingle(+id!)
                .then((response) => {
                    const {data} = response;
                    InitEditor(data?.text, handleText);
                    setState({...data, category: data?.category?.id, edition: data?.edition?.id});
                })
                .catch((error) => ErrorToast(error));
        } else {
            InitEditor(undefined, handleText);
        }
        AdminNewsCategoryAutoInformationService.getAll({perPage: 0, page: 1}, true)
            .then((response) => {
                const {data} = response;

                setCategoryList(
                    data?.items.map((item: INewsCategoryAutoInformation) => ({
                        label: item.title,
                        value: item.id,
                    }))
                );
            })
            .catch((error) => ErrorToast(error));
        AdminEditionsService.getAll({perPage: 0, page: 1}, true)
            .then((response) => {
                const {data} = response;
                setEditionList(
                    data?.items.map((item: IEditions) => ({
                        label: item.title,
                        value: item.id,
                    }))
                );
            })
    }, [id]);

    return (
        <Card>
            <Card.Body>
                <Row>
                    <Col md={10}>
                        <label htmlFor="title">
                            {t("global.title")}
                        </label>
                        <input type="text"
                               name={'title'}
                               value={state?.title}
                               onChange={handleChange}
                               className={'form-control'}
                               required
                        />
                    </Col>
                    <Col md={2}>
                        <div className="form-check form-switch p-0">
                            <label htmlFor="active">
                                {t("global.active")}
                            </label>
                            <div className="row">
                                <input
                                    className="form-check-input switch-custom ms-4 mt-2"
                                    type="checkbox"
                                    name={"active"}
                                    checked={state?.active}
                                    onChange={switchHandler}
                                    role="switch"
                                    id="active"
                                    required
                                />
                            </div>
                        </div>
                    </Col>
                    <Col md={6} className="my-3">
                        <label htmlFor="category" className="required-field">
                            {t("table_headers.category")}
                        </label>
                        <Select
                            className="react-select-field"
                            onChange={(e) => categoriesHandler(Number((e as unknown as IDropdown)?.value))}
                            options={categoryList}
                            value={categoryList?.find((category) => (category as unknown as IDropdown)?.value === state?.category)}
                            required
                        />
                    </Col>
                    <Col md={6} className="my-3">
                        <label htmlFor="category" className="required-field">
                            {t("table_headers.edition")}
                        </label>
                        <Select
                            className="react-select-field"
                            onChange={(e) => editionsHandler(Number((e as unknown as IDropdown)?.value))}
                            options={editionList}
                            value={editionList?.find((edition) => (edition as unknown as IDropdown)?.value === state?.edition)}
                            required
                        />
                    </Col>
                    <Col md={12}>
                        <label className="d-block text-start" htmlFor="text">
                            {t("global.text")}
                        </label>

                        <textarea className="editor text" onChange={console.log}></textarea>
                    </Col>

                    <Col md={12} className={'d-flex align-items-end justify-content-end mt-3'}>
                        <button
                            className="btn btn-info me-3"
                            type={"button"}
                            onClick={() => navigate("/admin/news-auto-information")}
                        >
                            {t("global.cancel")}
                        </button>
                        <button className="btn btn-primary" type="submit">
                            {t(state?.id ? "global.update" : "global.create")}
                        </button>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default CreateForm;