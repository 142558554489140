import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { ErrorToast, SuccessToast } from "../../shared/toasters/toasters";
import { AdminBrandService } from "./service";
import { useTranslation } from "react-i18next";
import { CreateForm } from "./components/CreateForm";
import { List } from "./components/List";
import DraggableBootstrapTable from "../../shared/components/draggableList/Draggable";
import {Pagination} from "../../shared/paginations/Paginations";

export const AdminBrand = () => {
  const { t } = useTranslation();
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [image, setImage] = useState<File>();
  const [currentBrand, setCurrentBrand] = useState<any>();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
    term: ""
  });
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [selectedPrompt, setSelectedPrompt] = useState<any>();
  const [brandsList, setBrandsList] = useState(undefined);
  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };

    const updatePosition = (newList: any) => {
        const newListUpdated = newList?.map((item: any) => item.id);
        AdminBrandService.updatePosition({ ids: newListUpdated });
    };
  const editHandler = (brandId: number) => {
    setIsMenuOpened(true);
    AdminBrandService.getSingle(brandId)
      .then((response) => {
        const { data } = response;
        setCurrentBrand(data);
        window.scrollTo(0, 0);
      })
      .catch((error) => ErrorToast(error));
  };
  const submitHandler = () => {
    const formData = new FormData();
    formData.append("body", JSON.stringify(currentBrand));
    image && formData.append("image", image);
    if (currentBrand.id) {
      AdminBrandService.update(formData, currentBrand.id)
        .then(() => {
          SuccessToast(t(`brand.successfully_updated`));
          setIsMenuOpened(false);
          setTriggerUpdate(!triggerUpdate);
          setCurrentBrand({});
        })
        .catch((error) => ErrorToast(error));
    } else {
      AdminBrandService.create(formData)
        .then(() => {
          SuccessToast(t(`brand.successfully_created`));
          setIsMenuOpened(false);
          setTriggerUpdate(!triggerUpdate);
          setCurrentBrand({});
        })
        .catch((error) => ErrorToast(error));
    }
  };
    const deleteHandler = () => {
        selectedPrompt?.id &&
        AdminBrandService.delete(selectedPrompt?.id)
            .then((response) => {
                if (response?.status === "success") {
                    SuccessToast(t(`brand.successfully_deleted`));
                    setPagination((prev)=> ({...prev, updatedAt: new Date().getTime()}))
                }
            })
            .catch((error) => ErrorToast(error));
    };
  useEffect(() => {
    AdminBrandService.getAll(pagination)
      .then((response) => {
        const { data } = response;
        setBrandsList(data?.items);
        setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
      })
      .catch((error) => ErrorToast(error));
  }, [triggerUpdate, pagination]);
  return (
    <>
      {!isMenuOpened && (
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row g-2">
                <div className="col-sm-4">
                  <div className="search-box">
                    <input
                      type="text"
                      className="form-control"
                      id="searchMemberList"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setPagination((prev) => ({
                              ...prev,
                              term: e.target.value,
                          }))
                      }
                      placeholder="Search for name or designation..."
                    />
                    <i className="ri-search-line search-icon"></i>
                  </div>
                </div>
                <div className="col-sm-auto ms-auto">
                  <div className="list-grid-nav hstack gap-1">
                    <button
                      className="btn btn-info"
                      onClick={() => setIsMenuOpened(true)}
                    >
                      {t("global.add")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isMenuOpened && (
        <div className="col-12">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              submitHandler();
            }}
          >
            <CreateForm
              setIsMenuOpened={setIsMenuOpened}
              state={currentBrand}
              setState={setCurrentBrand}
              image={image}
              setImage={setImage}
            />
          </Form>
        </div>
      )}
        {page > 1 || pagination?.term ? (
            <>
                <div className="col-12">
                    <List
                        brandsList={brandsList}
                        editHandler={editHandler}
                        deleteHandler={deleteHandler}
                        selectedPrompt={selectedPrompt}
                        setSelectedPrompt={setSelectedPrompt}
                        show={show}
                        setShow={setShow}
                    />
                </div>{" "}
            </>
        ) : (
            <div className="col-12">
                <DraggableBootstrapTable
                    preview={true}
                    list={brandsList!}
                    setList={setBrandsList}
                    headers={["id", "name"]}
                    title="brand"
                    onEdit={editHandler}
                    selectedPrompt={selectedPrompt}
                    setSelectedPrompt={setSelectedPrompt}
                    handleShow={handleShow}
                    show={show}
                    setShow={setShow}
                    deleteHandler={deleteHandler}
                    updatePosition={updatePosition}
                />
            </div>
        )}
        {page && (
            <div className="d-flex justify-content-end">
                {totalPages <= 1 ? (
                    ""
                ) : (
                    <Pagination
                        page={page}
                        totalPages={totalPages}
                        handlePagination={handlePages}
                    />
                )}
            </div>
        )}
    </>
  );
};
