import {Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import React, {useState} from "react";

import {useTranslation} from "react-i18next";
// import { AdminTestEmailService } from "../service";
import {Loader} from "../../../../shared/components/Loader";
import {EmptyState} from "../../../../shared/components/EmptyState";
import {ConfirmDelete} from "../../../../shared/components/ConfirmDelete";
import {Pagination} from "../../../../shared/paginations/Paginations";
import {ErrorToast} from "../../../../shared/toasters/toasters";
import {downloadFile} from "../../../../helpers/api.services";
import Select, {SingleValue} from "react-select";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store";

export const List = ({
  list,
  totalPages,
  page,
  handlePages,
  editHandler,
    setPagination
}: any) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [selectedPrompt] = useState<any>();
  const [selectedMonth, setSelectedMonth]= useState<string | null>( null);
  const [selectedYear, setSelectedYear] = useState<string | null>(null);
  const { months, years } = useSelector((state: RootState) => state.enum);

  const mappedMonths = months?.map((position: string) => ({
    label: position,
    value: position,
  }));

  const mappedYears = years?.map((position: string) => ({
    label: position,
    value: position,
  }));
  const downloadXLSFile = async () => {
    try {
      if(selectedMonth !== null && selectedYear === null){
        throw new Error(t("errors.year_must_be_selected"))
      }
      await downloadFile({
        route: `${
            process.env.NODE_ENV === "development"
                ? process.env.REACT_APP_DEV_MODE
                : process.env.REACT_APP_PRO_MODE
        }admin/newsletter/download`,
        fileName: 'download',
        params: {
          month: selectedMonth,
          year: selectedYear
        },
      });
    } catch (error) {
      ErrorToast({
        response: {
          data: {
            code: 500,
            message: (error as any)?.message || t("toasts.download_failed"),
            status: "error",
          },
        },
      });
    }
  };
  const handleSelectFilters = (
      selectedSingle: SingleValue<{ label: string; value: number }>,
      field: string
  ) => {
    if(field === 'months'){
      setSelectedMonth(selectedSingle?.value as unknown as string)
    }else{
      setSelectedYear(selectedSingle?.value as unknown as string)

    }

  };

  return (
    <div className="card">
      <div className="card-header d-flex align-items-center justify-content-between pb-0">
          <h4 className="card-title mg-b-0">
            {t("newsletter.list_sent_newsletter")}
          </h4>
        <div className={'d-flex align-items-center mb-2'}>
          <Select
              className="react-select-field"
              isClearable
              onChange={(e) => {
                // setSelectedMonth(e.value)
                handleSelectFilters(
                    e as SingleValue<{ label: string; value: number }>,
                    "months"
                );
              }}
              options={mappedMonths}
              isSearchable={false}
              placeholder={t("global.months")}
              required
          />
          <Select
              className="react-select-field mx-3"
              isClearable
              onChange={(e) => {
                handleSelectFilters(
                    e as SingleValue<{ label: string; value: number }>,
                    "years"
                );
              }}
              options={mappedYears}
              isSearchable={false}
              placeholder={t("global.years")}
              required
          />
          <button className="btn btn-sm btn-success" onClick={() => downloadXLSFile()}>
            {t('global.export')}
          </button>
        </div>
      </div>
      <div className="card-body">
        {list === undefined ? (
          <Loader />
        ) : list.length === 0 ? (
          <EmptyState />
        ) : (
          <div className="table-responsive">
            <Table className="align-middle table-nowrap mb-0">
              <thead className="table-light text-center">
                <tr className="">
                  <th scope="col">{t(`table_headers.id`)}</th>
                  <th scope="col">{t(`newsletter.newsletter`)}</th>
                  <th scope="col">{t(`newsletter.test`)}</th>
                  <th scope="col">{t(`newsletter.breakingNews`)}</th>
                  <th scope="col">{t(`newsletter.overview`)}</th>

                  <th scope="col">{t(`global.action`)}</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {list?.map((prompt: any) => {
                  return (
                    <tr key={prompt?.id} className="">
                      <td>
                        <Link to="#" className="fw-medium">
                          #{prompt?.id}
                        </Link>
                      </td>
                      <td>{prompt?.subject ?? "-"}</td>
                      <td>{prompt?.isTest ? "Test" : "-"}</td>
                      <td>{prompt?.isBreakingNews ? "Breaking news" : "-"}</td>

                      <td>{prompt?.count}</td>

                      <td>
                        <div className="d-flex align-items-center justify-content-center">
                          <button
                            className="btn btn-sm btn-warning d-flex align-items-center me-2"
                            onClick={() => editHandler(prompt?.id)}
                          >
                            <i className="ri-edit-2-fill me-1" />
                            {t("global.statistics")}
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        )}

        <div className="d-flex justify-content-end ">
          {totalPages <= 1 || list.length === 0 ? (
            ""
          ) : (
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePagination={handlePages}
            />
          )}
        </div>
        <ConfirmDelete
          show={show}
          setShow={setShow}
          itemName={selectedPrompt?.name}
          product={selectedPrompt}
          selectedProduct={selectedPrompt}
        />
      </div>
    </div>
  );
};
