import React, {ChangeEvent, SetStateAction, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {ErrorToast} from "../../../shared/toasters/toasters";
import {Card, Col, Row} from "react-bootstrap";
import {IEditions} from "../interface";
import {AdminEditionsService} from "../service";
import DatePicker from "react-datepicker";
import {PICTURE_URL} from "../../../helpers/api.routes";
import {InitEditor} from "../../../shared/tinyMce/InitEditor";
import {INewsAutoInformation} from "../../newsAutoInformation/interface";

interface IProps {
    state: IEditions | null;
    setState: React.Dispatch<SetStateAction<IEditions | null>>;
    setImage1: React.Dispatch<SetStateAction<File | null>>;
    setImage2: React.Dispatch<SetStateAction<File | null>>;
}

const CreateForm = ({state, setState, setImage1, setImage2}: IProps) => {
    const {t} = useTranslation();
    const {id} = useParams();
    const navigate = useNavigate();

    function handleChange(event: ChangeEvent<HTMLInputElement>, isNumber = false) {
        const {name, value, valueAsNumber} = event.target;
        setState((prev) => ({...prev, [name]: isNumber ? valueAsNumber : value}) as IEditions);
    }

    const switchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({...prev, [e.target.name]: e.target.checked}) as IEditions);
    };

    const fileHandler = (e: React.ChangeEvent<HTMLInputElement>, isFirst = false) => {
        const file = e.target.files?.[0];
        if (isFirst) {
            setImage1(file as File)
        } else {
            setImage2(file as File)
        }
    };

    const handleText = (imprint: string) => {
        setState((prev) => ({...prev, imprint}) as IEditions);
    };

    useEffect(() => {
        if (id) {
            AdminEditionsService.getSingle(+id!)
                .then((response) => {
                    const {data} = response;
                    InitEditor(data?.imprint, handleText);
                    setState({...data, category: data?.category?.id});
                })
                .catch((error) => ErrorToast(error));

        }else {
            InitEditor(undefined, handleText);
        }
    }, [id]);
    return (
        <Card>
            <Card.Body>
                <Row>
                    <Col md={6}>
                        <label htmlFor="title">
                            {t("global.title")}
                        </label>
                        <input type="text"
                               name={'title'}
                               value={state?.title}
                               onChange={handleChange}
                               className={'form-control'}
                               required
                        />
                    </Col>
                    <Col md={4}>
                        <label htmlFor="title">
                            {t("global.date")}
                        </label>
                        <div className="datepicker-container">
                            <DatePicker
                                className="date-picker"
                                dateFormat="dd/MM/yyyy"
                                selected={state?.date && new Date(state?.date)}
                                onChange={(date) =>
                                    setState((prev: any) => ({...prev, date: date}))
                                }
                                isClearable
                                placeholderText={t('global.choose_date')}
                                required
                            />
                        </div>
                    </Col>
                    <Col md={2}>
                        <div className="d-flex flex-column form-check form-switch p-0">
                            <label htmlFor="active">
                                {t("global.active")}
                            </label>
                            <input
                                className="form-check-input switch-custom ms-0 mt-2"
                                type="checkbox"
                                name={"active"}
                                checked={state?.active}
                                onChange={switchHandler}
                                role="switch"
                                id="active"
                            />
                        </div>
                    </Col>
                    <Col md={12} className={'my-3'}>
                        <label className="d-block text-start" htmlFor="text">
                            {t("editions.impressum")}
                        </label>

                        <textarea className="editor imprint" onChange={console.log}></textarea>
                    </Col>
                    <Col md={6} className={'mb-3'}>
                        <label htmlFor="name1">
                            {t("global.name1")}
                        </label>
                        <input type="text"
                               name={'name1'}
                               value={state?.name1}
                               onChange={handleChange}
                               className={'form-control'}
                               required
                        />
                    </Col>
                    <Col md={6} className={'mb-3'}>
                        <label htmlFor="name2">
                            {t("global.name2")}
                        </label>
                        <input type="text"
                               name={'name2'}
                               value={state?.name2}
                               onChange={handleChange}
                               className={'form-control'}
                               required
                        />
                    </Col>
                    <Col md={6} className="mt-2">
                        <div>
                            <label htmlFor="imageFile">{t("global.image1")}</label>
                            <input
                                onChange={(e) => fileHandler(e, true)}
                                className="form-control"
                                type="file"
                                accept="image/*"
                                id="imageFile"
                            />
                        </div>
                        {state?.image1 && (
                            <img
                                className="mt-3 rounded show-img-form"
                                alt="200x200"
                                src={PICTURE_URL + state?.image1?.path}
                                data-holder-rendered="true"
                            />
                        )}
                    </Col>
                    <Col md={6} className="mt-2">
                        <div>
                            <label htmlFor="imageFile-2">{t("global.image2")}</label>
                            <input
                                onChange={(e) => fileHandler(e, false)}
                                className="form-control"
                                type="file"
                                accept="image/*"
                                id="imageFile-2"
                            />
                        </div>
                        {state?.image2 && (
                            <img
                                className="mt-3 rounded show-img-form"
                                alt="200x200"
                                src={PICTURE_URL + state?.image2?.path}
                                data-holder-rendered="true"
                            />
                        )}
                    </Col>
                    <Col md={12} className={'d-flex align-items-end justify-content-end mt-3'}>
                        <button
                            className="btn btn-info me-3"
                            type={"button"}
                            onClick={() => navigate("/admin/editions")}
                        >
                            {t("global.cancel")}
                        </button>
                        <button className="btn btn-primary" type="submit">
                            {t(state?.id ? "global.update" : "global.create")}
                        </button>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default CreateForm;