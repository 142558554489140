import {requests} from "../../helpers/api.services";
import {NEWS_AUTO_INFORMATION_URL} from "../../helpers/api.routes";
import {IPagination} from "../../shared/interface";
import {INewsAutoInformation} from "./interface";

export const AdminNewsAutoInformationService = {
    getAll: (params: IPagination) => requests.get(`${NEWS_AUTO_INFORMATION_URL}`, params),
    create: (payload: INewsAutoInformation) => requests.post(`${NEWS_AUTO_INFORMATION_URL}`, payload),
    getSingle: (id: number) => requests.get(`${NEWS_AUTO_INFORMATION_URL}/${id}`),
    update: (payload: INewsAutoInformation, id: number) => requests.put(`${NEWS_AUTO_INFORMATION_URL}/${id}`, payload),
    delete: (id: number) => requests.delete(`${NEWS_AUTO_INFORMATION_URL}/${id}`),
    updatePosition: (ids: number[]) => requests.patch(`${NEWS_AUTO_INFORMATION_URL}`, {ids}),
}