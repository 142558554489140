import React, {useEffect, useState} from "react";

import {ErrorToast, SuccessToast} from "../../shared/toasters/toasters";
import {AdminNewsService} from "./service";
import {List} from "./components/List";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import DraggableBootstrapTable from "../../shared/components/draggableList/Draggable";
import {Pagination} from "../../shared/paginations/Paginations";
import Select, {SingleValue} from "react-select";
import {AdminCategoryService} from "../category/service";
import {AdminBrandService} from "../brand/service";
import {AdminNewsThemeService} from "../newsTheme/service";
import {perPageOptions} from "../../shared/paginations/perPageOptions";
import {AdminWaitingNewsService} from "../waitingNews/service";
import {NEWS_URL} from "../../helpers/api.routes";
import axios, {AxiosRequestConfig} from "axios";

export const AdminNews = () => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleShow = () => setShow(true);
  const [selectedPrompt, setSelectedPrompt] = useState<any>();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [state, setState] = useState<any>();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const domain = useSelector((state: any) => state.company.clientDomain);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 25,
    term: "",
  });
  const [themeList, setThemeList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [list, setList] = useState([]);
  const [brandsList, setBrandsList] = useState([]);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const mappedDataCategory = categoryList?.map((position: any) => ({
    label: position.name,
    value: position.id,
  }));
  const mappedDataBrand = brandsList?.map((position: any) => ({
    label: position.name,
    value: position.id,
  }));
  const mappedDataTheme = themeList?.map((position: any) => ({
    label: position.title,
    value: position.id,
  }));
  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };
  const editHandler = (id: number): void => {
    navigate(`/admin/news/update/${id}`);
  };

  const handleDownload = async (id: number) => {
    try {
      setIsDownloadLoading(true);
      const website = localStorage.getItem("website");
      const token = localStorage.getItem("token");
      if (!token) throw new Error("No authentication token found");

      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Aw-Web-Site": website ?? "aw",
      };

      const config: AxiosRequestConfig = {
        method: "PATCH",
        url: `${NEWS_URL}/export/${id}`,
        responseType: "blob",
        headers,
      };

      const response = await axios(config);

      const url = URL.createObjectURL(new Blob([response.data], { type: "application/zip" }));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `export_${id}.zip`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading ZIP file:", error);
    } finally {
      setIsDownloadLoading(false)
    }
  };

  const updatePosition = (newList: any) => {
    const newListUpdated = newList?.map((item: any) => item.id);
    AdminNewsService.updatePosition({ ids: newListUpdated });
  };
  const deleteHandler = () => {
    selectedPrompt?.id &&
      AdminNewsService.delete(selectedPrompt?.id)
        .then((response) => {
          if (response?.status === "success") {
            SuccessToast(t(`news.successfully_deleted`));
            setTriggerUpdate((prev: boolean) => !prev);
          }
        })
        .catch((error) => ErrorToast(error));
  };
  const handlePerPage = (e: any) => {
    setPagination((prev) => ({ ...prev, page: 1, perPage: e.value }));
  };
  const handleSelectFilters = (
    selectedSingle: SingleValue<{ label: string; value: number }>,
    field: string
  ) => {
    if (selectedSingle?.value as number) {
      setPagination((prev: any) => ({
        ...prev,
        [field]: selectedSingle?.value,
        page: 1,
      }));
    } else {
      setPagination((prev: any) => ({
        ...prev,
        [field]: null,
        page: 1,
      }));
    }
  };

  const moveToWaitingList = async () => {
    await AdminWaitingNewsService.update(selectedIds)
        .then(response => {
          if (response?.status === 'success') {
            setSelectedIds([]);
            SuccessToast(t('news.successfully_updated'));
            setTriggerUpdate((prev) => !prev);
          }
        }).catch(err => ErrorToast(err));
  }
  const handleCheckAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const mapped = list?.map((product: any) => product.id);
      setSelectedIds(mapped as number[]);
    } else {
      setSelectedIds([]);
    }
  };
  useEffect(() => {
    AdminNewsService.getAll(pagination)
      .then((response) => {
        const { data } = response;
        setList(data?.items);
        setPage(data?.page);
        setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
      })
      .catch((error) => ErrorToast(error));
  }, [triggerUpdate, pagination]);

  useEffect(() => {
    AdminCategoryService.getAll({ perPage: 0, page: 1 })
      .then((response) => {
        const { data } = response;
        setCategoryList(data?.items);
      })
      .catch((error) => ErrorToast(error));
    AdminBrandService.getAll({ perPage: 0, page: 1 })
      .then((response) => {
        const { data } = response;
        setBrandsList(data?.items);
      })
      .catch((error) => ErrorToast(error));
    AdminNewsThemeService.getAll({ perPage: 0, page: 1 })
      .then((response) => {
        const { data } = response;
        setThemeList(data?.items);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body d-flex align-items-center justify-content-between">
              <h4 className="card-title mg-b-0">{t("news.create_news")}</h4>
              <button
                className="btn btn-primary"
                onClick={() => navigate("/admin/news/create")}
              >
                {t("global.add")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-2">
                  {/* <h6 className="my-auto me-2 p-0 ">Per page:</h6> */}
                  <Select
                    className="react-select-field"
                    onChange={handlePerPage}
                    placeholder="Per page"
                    options={perPageOptions}
                    defaultValue={perPageOptions[0]}
                  />
                </div>
                <div className="col-3">
                  <div className="search-box">
                    <input
                      style={{ height: "38px" }}
                      type="text"
                      className="form-control"
                      id="searchMemberList"
                      onChange={(e: any) =>
                        setPagination((prev: any) => ({
                          ...prev,
                          term: e.target.value,
                        }))
                      }
                      placeholder={t("global.search")}
                    />
                    <i className="ri-search-line search-icon"></i>
                  </div>
                </div>
                <div className="col-3">
                  <Select
                    className="react-select-field"
                    isClearable
                    onChange={(e) => {
                      handleSelectFilters(
                        e as SingleValue<{ label: string; value: number }>,
                        "category"
                      );
                    }}
                    options={mappedDataCategory}
                    isSearchable={false}
                    placeholder={t("global.category")}
                    required
                  />
                </div>
                <div className="col-2">
                  <Select
                    className="react-select-field"
                    isClearable
                    onChange={(e) => {
                      handleSelectFilters(
                        e as SingleValue<{ label: string; value: number }>,
                        "theme"
                      );
                    }}
                    options={mappedDataTheme}
                    isSearchable={false}
                    placeholder={t("global.theme")}
                    required
                  />
                </div>
                <div className="col-2">
                  <Select
                    className="react-select-field"
                    isClearable
                    onChange={(e) => {
                      handleSelectFilters(
                        e as SingleValue<{ label: string; value: number }>,
                        "brand"
                      );
                    }}
                    options={mappedDataBrand}
                    isSearchable={false}
                    placeholder={t("side_bar.brand")}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {page > 1 || (pagination as any)?.term !== "" ? (
        <>
          <div className="row">
            <div className="col-12">
              <List
                page={page}
                handlePages={handlePages}
                totalPages={totalPages}
                list={list}
                setTriggerUpdate={setTriggerUpdate}
                editHandler={editHandler}
                domain={domain}
                setList={setList}
                ctaHeaderBtnTitle={t('news.move_to_waiting_list')}
                ctaHeaderBtnClick={moveToWaitingList}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
                handleCheckAll={handleCheckAll}
                handleDownload={handleDownload}
                isDownloadDisabled={isDownloadLoading}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="row">
            <div className="col-12">
              <DraggableBootstrapTable
                preview={false}
                list={list}
                setList={setList}
                headers={["id", "theme", "title", "date", "isActive"]}
                title="news"
                onEdit={editHandler}
                selectedPrompt={selectedPrompt}
                setSelectedPrompt={setSelectedPrompt}
                handleShow={handleShow}
                show={show}
                setShow={setShow}
                deleteHandler={deleteHandler}
                updatePosition={updatePosition}
                setTriggerUpdate={setTriggerUpdate}
                ctaHeaderBtnTitle={t('news.move_to_waiting_list')}
                ctaHeaderBtnClick={moveToWaitingList}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
                handleCheckAll={handleCheckAll}
                handleDownload={handleDownload}
                isDownloadDisabled={isDownloadLoading}
              />
            </div>
          </div>
        </>
      )}

      {page && (
        <div className="d-flex justify-content-end ">
          {totalPages <= 1 ? (
            ""
          ) : (
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePagination={handlePages}
            />
          )}
        </div>
      )}
    </>
  );
};
