import {requests} from "../../helpers/api.services";
import {SPEAKER_URL} from "../../helpers/api.routes";

export const AdminSpeakerService = {
    getAll: (params: any, active?: boolean) => requests.get(SPEAKER_URL, { ...params, active }),
    getSingle: (id: number) => requests.get(`${SPEAKER_URL}/${id}`),
    create: (params: any) => requests.post(SPEAKER_URL, params, true),
    update: (params: any, id: number) => requests.post(`${SPEAKER_URL}/${id}`, params, true),
    delete: (id: number) => requests.delete(`${SPEAKER_URL}/${id}`),
    deleteSpecification: (id: number) => requests.delete(`${SPEAKER_URL}/specification/${id}`),
    deleteImage: (id: number) => requests.delete(`${SPEAKER_URL}/image/${id}`),
    updatePosition: (params: any) => requests.patch(`${SPEAKER_URL}`, params),
}