import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {ErrorToast, SuccessToast} from "../../../shared/toasters/toasters";
import {Form} from "react-bootstrap";
import {IEditions} from "../interface";
import {AdminEditionsService} from "../service";
import CreateForm from "./CreateForm";

const CreateEdition = () => {
    const {t} = useTranslation();
    const navigate = useNavigate()
    const [state, setState] = useState<IEditions | null>(null);
    const [image1, setImage1] = useState<File | null>(null);
    const [image2, setImage2] = useState<File | null>(null);
    const submitHandler = () => {
        const formData = new FormData();
        formData.append('body', JSON.stringify(state))
        image1 && formData.append("image1", image1);
        image2 && formData.append("image2", image2);

        AdminEditionsService.create(formData)
            .then(() => {
                SuccessToast(t(`editions.successfully_created`));
                navigate('/admin/editions');
                setState(null);
            })
            .catch((error) => ErrorToast(error));
    };
    return (
        <div className="col-12">
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    submitHandler();
                }}
            >
                <CreateForm state={state} setState={setState} setImage1={setImage1} setImage2={setImage2}/>
            </Form>
        </div>
    );
};

export default CreateEdition;