import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {ErrorToast, SuccessToast} from "../../shared/toasters/toasters";
import {INotification, INotificationList} from "./interface";
import {AdminNotificationService} from "./service";
import {Card, Col, Form, Row} from "react-bootstrap";
import {BarChart} from "../../shared/components/BarChart";

const AdminNotifications = () => {
    const {t} = useTranslation();
    const [state, setState] = useState<INotification>();
    const [notifications, setNotifications] = useState<INotificationList | null>(null);
    const [updatedAt, setUpdatedAt] = useState(new Date()?.getTime());
    const tabs = ['android', 'iOS'];

    const [activeTab, setActiveTab] = useState(tabs[0]);

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setState((prev) => ({...prev, [name]: value}) as INotification);
    };
    const submitHandler = () => {
        AdminNotificationService.create(state)
            .then(() => {
                setUpdatedAt(new Date()?.getTime())
                SuccessToast(t(`notifications.successfully_saved`));
            })
            .catch((error) => ErrorToast(error));
    };

    useEffect(() => {
        AdminNotificationService.get(activeTab)
            .then((response) => {
                const {data} = response;
                console.log(data, 'data')
                setNotifications(data);
            })
            .catch((error) => ErrorToast(error));
    }, [activeTab, updatedAt]);

    return (
        <div>
            <ul className="nav nav-tabs nav-tabs-custom rounded card-header-tabs"
                id="myTab"
                role="tablist"
            >
                {tabs?.map(tab => {
                    return (
                        <li className="nav-item" role="presentation" key={tab}>
                            <button
                                onClick={() => setActiveTab(tab)}
                                className={`nav-link ${activeTab === tab ? 'active' : ''}`}
                                id={`${tab}-tab`}
                                data-bs-toggle="tab"
                                data-bs-target={`#${tab}`}
                                type="button"
                                role="tab"
                                aria-controls={tab}
                                aria-selected="true"
                            >
                                {t(`table_headers.${tab}`)}
                            </button>
                        </li>
                    )
                })}
            </ul>
            <div className="tab-content mt-1" id="myTabContent">
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        submitHandler();
                    }}
                >
                    <Card>
                        <Card.Header>
                            <h5 className={'mb-0'}>
                                {t('subscribers.subscribers')}: {notifications?.subscribers}
                             </h5>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col md={6}>
                                    <label htmlFor="title" className="required-field">
                                        {t("global.title")}
                                    </label>
                                    <input
                                        type="text"
                                        name="title"
                                        className="form-control"
                                        required
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeHandler(e)}
                                        value={state?.id && state?.title}
                                    />
                                </Col>
                                <Col md={6}>
                                    <label htmlFor="message" className="required-field">
                                        {t("global.subtitle")}
                                    </label>
                                    <input
                                        type="text"
                                        name="message"
                                        className="form-control"
                                        required
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeHandler(e)}
                                        value={state?.id && state?.message}
                                    />
                                </Col>
                            </Row>

                            <div className={'d-flex justify-content-end my-3'}>
                                <button className="btn btn-primary" type="submit">
                                    {t("global.send")}
                                </button>
                            </div>
                            <div className={'my-3'}>
                                <BarChart data={(notifications?.graph ?? []) as any}/>
                            </div>
                        </Card.Body>
                    </Card>
                </Form>
            </div>
        </div>
    );
};

export default AdminNotifications;