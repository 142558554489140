import {requests} from "../../helpers/api.services";
import {CATEGORY_AUTO_INFORMATION_URL} from "../../helpers/api.routes";
import {IPagination} from "../../shared/interface";
import {INewsCategoryAutoInformation} from "./interface";

export const AdminNewsCategoryAutoInformationService = {
    getAll: (params: IPagination, active?: boolean) => requests.get(`${CATEGORY_AUTO_INFORMATION_URL}`, {...params, active}),
    getSingle: (id: number) => requests.get(`${CATEGORY_AUTO_INFORMATION_URL}/${id}`),
    create: (payload: INewsCategoryAutoInformation) => requests.post(`${CATEGORY_AUTO_INFORMATION_URL}`, payload),
    update: (payload: INewsCategoryAutoInformation, id: number) => requests.put(`${CATEGORY_AUTO_INFORMATION_URL}/${id}`, payload),
    delete: (id: number) => requests.delete(`${CATEGORY_AUTO_INFORMATION_URL}/${id}`),
    updatePosition: (ids: number[]) => requests.patch(`${CATEGORY_AUTO_INFORMATION_URL}`, {ids}),
}