import {requests} from "../../helpers/api.services";
import {SPEAKER_SPECIFICATION_URL} from "../../helpers/api.routes";
import {ISpeakerSpecification} from "./index";
import {IPagination} from "../../shared/interface";

export const AdminSpeakerSpecificationService = {
    getAll: (params: IPagination, active?: boolean) => requests.get(SPEAKER_SPECIFICATION_URL, {...params, active}),
    getSingle: (id: number) => requests.get(`${SPEAKER_SPECIFICATION_URL}/${id}`),
    create: (payload: ISpeakerSpecification) => requests.post(SPEAKER_SPECIFICATION_URL, payload),
    update: (payload: ISpeakerSpecification, id: number) => requests.put(`${SPEAKER_SPECIFICATION_URL}/${id}`, payload),
    delete: (id: number) => requests.delete(`${SPEAKER_SPECIFICATION_URL}/${id}`),
    updatePosition: (ids: number[]) => requests.patch(`${SPEAKER_SPECIFICATION_URL}`, {ids}),
}