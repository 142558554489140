import React, {useState} from 'react';
import {Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {ErrorToast, SuccessToast} from "../../../shared/toasters/toasters";
import {ISubscriberAutoInformation} from "../interface";
import {AdminSubscriberAutoInformationService} from "../service";
import CreateForm from "./CreateForm";

const CreateSubscriber = () => {
    const {t} = useTranslation();
    const navigate = useNavigate()
    const [state, setState] = useState<ISubscriberAutoInformation | null>(null);
    const submitHandler = () => {
        const payload = {
            ...state,
            numberOfDevice: Number(state?.numberOfDevice),
            registeredDevice: Number(state?.registeredDevice),
        };

        AdminSubscriberAutoInformationService.create(payload as ISubscriberAutoInformation)
            .then(() => {
                SuccessToast(t(`subscribers.successfully_created`));
                navigate('/admin/auto-information/subscriber');
                setState(null);
            })
            .catch((error) => ErrorToast(error));
    };

    return (
        <div className="col-12">
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    submitHandler();
                }}
            >
                <CreateForm state={state} setState={setState}/>
            </Form>
        </div>
    );
};

export default CreateSubscriber;