import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ErrorToast, SuccessToast} from "../../shared/toasters/toasters";
import DraggableBootstrapTable from "../../shared/components/draggableList/Draggable";
import {AdminInFocusService} from "./service";

const AdminInFocus = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [show, setShow] = useState(false);
    const [triggerUpdate, setTriggerUpdate] = useState(false);
    const [selectedPrompt, setSelectedPrompt] = useState<any>();
    const [list, setList] = useState([]);
    const handleShow = () => setShow(true);
    const editHandler = (id: number): void => {
        navigate(`/admin/news/update/${id}`);
    };

    const deleteHandler = () => {
        selectedPrompt?.id &&
        AdminInFocusService.update(selectedPrompt?.id)
            .then((response) => {
                if (response?.status === "success") {
                    SuccessToast(t(`news.successfully_updated`));
                    setTriggerUpdate((prev: boolean) => !prev);
                }
            })
            .catch((error) => ErrorToast(error));
    };
    const updatePosition = (newList: any) => {
        const newListUpdated = newList?.map((item: any) => item.id);
        AdminInFocusService.updatePosition({ids: newListUpdated});
    };
    useEffect(() => {
        AdminInFocusService.getAll()
            .then((response) => {
                const {data} = response;
                setList(data);
            })
            .catch((error) => ErrorToast(error));
    }, [triggerUpdate]);
    return (
        <div className="col-lg-12">
            <div className="card">
                <div className="card-body d-flex align-items-center">
                    <div className="col-sm-auto ms-auto">
                        <div className="list-grid-nav hstack gap-1">
                            <button
                                className="btn btn-info"
                                onClick={() => navigate("/admin/news/create?inFocus=true")}
                            >
                                {t("global.add")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <DraggableBootstrapTable
                preview={true}
                list={list}
                setList={setList}
                headers={["id", "title", "isActive"]}
                title="in_focus"
                onEdit={editHandler}
                selectedPrompt={selectedPrompt}
                setSelectedPrompt={setSelectedPrompt}
                handleShow={handleShow}
                show={show}
                setShow={setShow}
                deleteHandler={deleteHandler}
                updatePosition={updatePosition}
            />
        </div>
    );
};

export default AdminInFocus;