import {IPagination} from "../../shared/interface";
import {requests} from "../../helpers/api.services";
import {SUBSCRIBER_AUTO_INFORMATION_URL} from "../../helpers/api.routes";
import {ISubscriberAutoInformation} from "./interface";

export const AdminSubscriberAutoInformationService = {
    getAll: (params: IPagination, active?: boolean) => requests.get(`${SUBSCRIBER_AUTO_INFORMATION_URL}`, {...params, active}),
    getSingle: (id: number) => requests.get(`${SUBSCRIBER_AUTO_INFORMATION_URL}/${id}`),
    create: (payload: ISubscriberAutoInformation) => requests.post(`${SUBSCRIBER_AUTO_INFORMATION_URL}`, payload),
    update: (payload: ISubscriberAutoInformation, id: number) => requests.put(`${SUBSCRIBER_AUTO_INFORMATION_URL}/${id}`, payload),
    delete: (id: number) => requests.delete(`${SUBSCRIBER_AUTO_INFORMATION_URL}/${id}`),
}