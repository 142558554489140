import React, {useEffect, useState} from 'react';
import {Form} from "react-bootstrap";
import DraggableBootstrapTable from "../../shared/components/draggableList/Draggable";
import {Pagination} from "../../shared/paginations/Paginations";
import {useTranslation} from "react-i18next";
import {ErrorToast, SuccessToast} from "../../shared/toasters/toasters";
import {AdminNewsCategoryAutoInformationService} from "./service";
import {List} from "./components/List";
import {CreateForm} from "./components/CreateForm";
import {INewsCategoryAutoInformation} from "./interface";
import {IPagination} from "../../shared/interface";

const AdminNewsCategoryAutoInformation = () => {
    const {t} = useTranslation();
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const [state, setState] = useState<INewsCategoryAutoInformation | null>(null);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [selectedPrompt, setSelectedPrompt] = useState<INewsCategoryAutoInformation | null>(null);
    const [pagination, setPagination] = useState<IPagination>({
        page: 1,
        perPage: 10,
        term: "",
    });
    const [list, setList] = useState<any>(undefined);

    const deleteHandler = () => {
        selectedPrompt?.id &&
        AdminNewsCategoryAutoInformationService.delete(selectedPrompt?.id)
            .then((response) => {
                if (response?.status === "success") {
                    SuccessToast(t(`category.successfully_deleted`));
                    setPagination(prev => ({...prev, updatedAt: new Date()?.getTime()}))
                }
            })
            .catch((error) => ErrorToast(error));
    };
    const handlePages = (updatePage: number) => {
        setPagination({...pagination, page: updatePage});
        setPage(updatePage);
    };
    const editHandler = (id: number) => {
        setIsMenuOpened(true);
        AdminNewsCategoryAutoInformationService.getSingle(id)
            .then((response) => {
                const {data} = response;
                setState(data);
                window.scrollTo(0, 0);
            })
            .catch((error) => ErrorToast(error));
    };

    const updatePosition = (newList: INewsCategoryAutoInformation[]) => {
        const newListUpdated = newList?.map((item) => item.id) as number[];
        AdminNewsCategoryAutoInformationService.updatePosition(newListUpdated);
    };
    const submitHandler = () => {
        if (state?.id) {
            AdminNewsCategoryAutoInformationService.update(state, state?.id as number)
                .then(() => {
                    SuccessToast(t(`category.successfully_updated`));
                    setIsMenuOpened(false);
                    setState(null);
                    setPagination(prev => ({...prev, updatedAt: new Date()?.getTime()}))

                })
                .catch((error) => ErrorToast(error));
        } else {
            AdminNewsCategoryAutoInformationService.create(state as INewsCategoryAutoInformation)
                .then(() => {
                    SuccessToast(t(`category.successfully_created`));
                    setIsMenuOpened(false);
                    setState(null);
                    setPagination(prev => ({...prev, updatedAt: new Date()?.getTime()}))

                })
                .catch((error) => ErrorToast(error));
        }
    };

    useEffect(() => {
        AdminNewsCategoryAutoInformationService.getAll(pagination)
            .then((response) => {
                const {data} = response;
                setList(data?.items);
                setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
            })
            .catch((error) => ErrorToast(error));
    }, [pagination]);
    return (
        <>
            {!isMenuOpened && (
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row g-2">
                                <div className="col-sm-4">
                                    <div className="search-box">
                                        <input
                                            type="text"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                setPagination((prev) => ({
                                                    ...prev,
                                                    term: e.target.value,
                                                }))
                                            }
                                            className="form-control"
                                            id="searchMemberList"
                                            placeholder="Search for category name..."
                                        />
                                        <i className="ri-search-line search-icon"></i>
                                    </div>
                                </div>
                                <div className="col-sm-auto ms-auto">
                                    <div className="list-grid-nav hstack gap-1">
                                        <button
                                            className="btn btn-info"
                                            onClick={() => setIsMenuOpened(true)}
                                        >
                                            {t("global.add")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {isMenuOpened && (
                <div className="col-12">
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            submitHandler();
                        }}
                    >
                        <CreateForm
                            setIsMenuOpened={setIsMenuOpened}
                            state={state}
                            setState={setState}
                        />
                    </Form>
                </div>
            )}
            {page > 1 || (pagination)?.term !== "" ? (
                <div className="col-12">
                    <List
                        state={state as any}
                        editHandler={editHandler}
                        deleteHandler={deleteHandler}
                    />
                </div>
            ) : (
                <div className="col-12">
                    <DraggableBootstrapTable
                        list={list}
                        setList={setList}
                        headers={["id", "title", "active"]}
                        title="category"
                        onEdit={editHandler}
                        handleShow={handleShow}
                        selectedPrompt={selectedPrompt}
                        setSelectedPrompt={setSelectedPrompt}
                        show={show}
                        setShow={setShow}
                        deleteHandler={deleteHandler}
                        updatePosition={updatePosition}
                        preview={true}
                    />
                </div>
            )}

            {page && (
                <div className="d-flex justify-content-end">
                    {totalPages <= 1 ? (
                        ""
                    ) : (
                        <Pagination
                            page={page}
                            totalPages={totalPages}
                            handlePagination={handlePages}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default AdminNewsCategoryAutoInformation;