import React, {useEffect, useState} from 'react';
import {Card, Col, Form, Row} from "react-bootstrap";
import {PICTURE_URL} from "../../../helpers/api.routes";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ErrorToast, SuccessToast} from "../../../shared/toasters/toasters";
import {AdminSpeakerService} from "../service";
import {ConfirmDelete} from "../../../shared/components/ConfirmDelete";
import {AdminSpeakerSpecificationService} from "../../speakerSpecification/service";
import {ISpeakerSpecification} from "../../speakerSpecification";
import CreateSpecificationModal from "./CreateSpecificationModal";

export interface ISpecification {
    indicator: number;
    id?: number;
    value: string;
    key: string;
}

const CreateForm = ({state, setState, setImage, specificationData, setSpecificationData}: any) => {
    const {id} = useParams();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [selectedPrompt, setSelectedPrompt] = useState<any>();
    const [specificationList, setSpecificationList] = useState<ISpeakerSpecification[]>([]);
    const [isCreateSpecificationOpen, setIsCreateSpecificationOpen] = useState(false);
    const [selectedSpecification, setSelectedSpecification] = useState<ISpecification | undefined>(undefined);
    const [name, setName] = useState('')

    const handleShow = () => setShow(true);

    const changeHandler = (event: any) => {
        const {name, value} = event.target;
        setState((prev: any) => ({...prev, [name]: value}));
    };
    const fileHandler = (e: any) => {
        setImage(e.target.files[0]);
    };
    const switchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev: any) => ({...prev, [e.target.name]: e.target.checked}));
    };

    const changeSpecificationHandler = (e: React.ChangeEvent<HTMLInputElement>, currId: number) => {
        const {name, value} = e.target;
        const adjusted = specificationData?.map((data: ISpecification) => {
            if (data?.indicator === currId) {
                return {
                    ...data,
                    [name]: value,
                }
            }
            return data;
        })
        setSpecificationData(adjusted)
    }

    const handleDeleteSpecification = async (specification?: ISpecification) => {
        if (!specification?.id) {
            setSpecificationData((prev: ISpecification[]) => (prev || [])
                ?.filter((item: ISpecification) => item?.indicator !== specification?.indicator))
        } else {
            await AdminSpeakerService.deleteSpecification(specification?.id)
                .then(response => {
                    if (response) {
                        setSpecificationData((prev: ISpecification[]) => (prev || [])
                            ?.filter((item: ISpecification) => item?.indicator !== specification?.indicator))
                    }
                }).catch(err => ErrorToast(err));
        }
    }

    const deleteImageHandler = () => {
        selectedPrompt &&
        AdminSpeakerService.deleteImage(selectedPrompt?.id)
            .then((response) => {
                if (response?.status === "success") {
                    SuccessToast(t(`speaker.successfully_deleted_image`));
                    const {data} = response;
                    setState(data);
                }
            })
            .catch((error) => ErrorToast(error));
    };

    const handleCreateSpecification = () => {
        AdminSpeakerSpecificationService.create({
            active: true,
            name
        }).then(response => {
            setSpecificationList(prev => prev.concat(response?.data));
            changeSpecificationHandler({
                // @ts-ignore
                target: {
                    value: response?.data?.name,
                    name: 'key'
                }
            }, selectedSpecification?.indicator)
        })
            .catch((error) => ErrorToast(error));
    }

    const handleCloseCreate = () => {
        setName('')
        setSelectedSpecification(undefined)
        setIsCreateSpecificationOpen(false);
    }

    useEffect(() => {
        if (Number(id)) {
            AdminSpeakerService.getSingle(Number(id))
                .then((response) => {
                    const {data} = response;
                    setState(data);
                    setSpecificationData(data?.specificationData
                        ?.map((item: ISpecification) => ({...item, indicator: item?.id})))
                })
                .catch((error) => ErrorToast(error));
        }
    }, [id]);

    useEffect(() => {
        AdminSpeakerSpecificationService.getAll({page: 1, perPage: 0}, true)
            .then(response => {
                setSpecificationList(response?.data?.items)
            })
            .catch(err => ErrorToast(err))
    }, [])
    return (
        <Card>
            <Card.Body>
                <Row>
                    <Col md={4}>
                        <label htmlFor="name" className="required-field">
                            {t("global.name")}
                        </label>
                        <input
                            type="text"
                            name="title"
                            className="form-control"
                            required
                            onChange={(e) => changeHandler(e as any)}
                            value={state?.id && state?.title}
                        />
                    </Col>
                    <Col md={2} className="">
                        <div className="form-check form-switch p-0">
                            <label
                                className="d-block text-start"
                                htmlFor="activeSwitch"
                            >
                                {t("global.isActive")}
                            </label>
                            <div className="row">
                                <input
                                    className="form-check-input switch-custom ms-4 mt-2"
                                    type="checkbox"
                                    name={"isActive"}
                                    checked={state?.isActive}
                                    onChange={switchHandler}
                                    role="switch"
                                    id="activeSwitch"
                                />
                            </div>
                        </div>
                    </Col>

                    <Col md={12} className={'mt-3'}/>
                    {specificationData?.map((specification: ISpecification) => {
                        return (
                            <React.Fragment key={specification?.indicator}>
                                <Col md={6} className={'mt-2'}>
                                    <label htmlFor="name" className="required-field">
                                        {t("global.key")}
                                    </label>
                                    <Form.Select className={'form-control'}
                                                 value={specification?.key}
                                                 onChange={(e) => {
                                                     const {value} = e.target;
                                                     const selected = specificationList?.find(item => item.name === value);
                                                     if (!selected) {
                                                         setIsCreateSpecificationOpen(true);
                                                         setSelectedSpecification(specification)
                                                     } else {
                                                         setSpecificationData((prev: any) => prev.map((currSpec: ISpecification) => {
                                                             if (currSpec?.indicator === specification?.indicator) {
                                                                 return {
                                                                     ...currSpec,
                                                                     key: selected?.name
                                                                 }
                                                             }
                                                             return currSpec
                                                         }))
                                                     }
                                                 }}>
                                        <option value="" hidden>Please select one option</option>
                                        {specificationList?.map(specificationItem => {
                                            return (
                                                <option value={specificationItem?.name} key={specificationItem?.id}>
                                                    {specificationItem?.name}
                                                </option>
                                            )
                                        })}
                                        <option value="-1">Add new</option>
                                    </Form.Select>
                                </Col>
                                <Col md={6} className={'d-flex align-items-end mt-2'}>
                                    <div className={'w-100'}>
                                        <label htmlFor="name" className="required-field">
                                            {t("global.value")}
                                        </label>
                                        <input
                                            type="text"
                                            name="value"
                                            className="form-control"
                                            required
                                            onChange={(e) => changeSpecificationHandler(e, Number(specification?.indicator))}

                                            value={state?.id && specification?.value}
                                        />
                                    </div>
                                    <button className="btn btn-danger btn-sm ms-1 mb-1" type={'button'}
                                            onClick={() => handleDeleteSpecification(specification)}>
                                        {t('global.delete')}
                                    </button>
                                </Col>
                            </React.Fragment>
                        )
                    })}
                    <Col md={12} className={'d-flex justify-content-end mt-3'}>
                        <button className="btn btn-primary btn-sm" type={'button'}
                                onClick={() => setSpecificationData((prev: ISpecification[]) => (prev || []).concat({
                                    key: '',
                                    value: '',
                                    indicator: Math.random(),
                                }))}>
                            Add new specification
                        </button>
                    </Col>
                    <Col md={12} className="mt-3">
                        <label htmlFor="imageFile"> {t("download.image")}</label>
                        <input
                            onChange={fileHandler}
                            className="form-control"
                            type="file"
                            accept="image/*"
                            id="imageFile"
                        />
                    </Col>
                        {state?.image && (
                            <div className={'mt-3 w-auto pe-0'} key={state?.image?.key}>
                                <img
                                    className="object-fit-cover"
                                    width={250}
                                    height={250}
                                    src={PICTURE_URL + state?.image.path}
                                    data-holder-rendered="true"
                                    alt={state?.image?.key}
                                    style={{margin: '2px'}}
                                />
                                <i onClick={() => {
                                    handleShow();
                                    setSelectedPrompt(state);
                                }}
                                   className={'ri-delete-bin-fill bg-danger text-white delete-icon-position'}/>
                            </div>
                        )}
                </Row>
                <div className="col-12 my-2 d-flex justify-content-end text-align-center">
                    <div>
                        <button
                            className="btn btn-info"
                            type={'button'}
                            onClick={() => {
                                navigate(-1);
                                setState({} as any);
                            }}
                        >
                            {t("global.cancel")}
                        </button>
                        <button className="btn btn-primary ms-3" type="submit">
                            {state?.id ? `  ${t("global.update")}` : `${t("global.create")}`}
                        </button>
                    </div>
                </div>
            </Card.Body>
            <CreateSpecificationModal show={isCreateSpecificationOpen}
                                      handleCreate={handleCreateSpecification}
                                      name={name} setName={setName}
                                      handleClose={handleCloseCreate}
                                      title={t('speaker_specifications.create')}
            />
            <ConfirmDelete
                show={show}
                setShow={setShow}
                itemName={selectedPrompt?.title}
                deleteHandler={deleteImageHandler}
                product={selectedPrompt}
                selectedProduct={selectedPrompt}
            />
        </Card>
    );
};

export default CreateForm;