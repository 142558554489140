import React, {useEffect, useRef, useState} from "react";
import {Card, Col, Row} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import Select from "react-select";
import {PICTURE_URL} from "../../../helpers/api.routes";
import {ErrorToast} from "../../../shared/toasters/toasters";
import {AdminModelService} from "../service";
import {ISelect} from "../../../shared/interface";
// @ts-ignore
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { InitEditorMulti } from "../../../shared/tinyMce/InitEditorMulti";

export const CreateForm = ({
  state,
  brands,
  modelCats,
  setState,
  setImage,
}: any) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectEditor, setSelectEditor] = useState<boolean>(false);
  const firstLoad = useRef(true);
  const [mainImage, setMainImage] = useState<any>("");
  const [mainLogo, setMainLogo] = useState<any>("");
  const [editorData, setEditorData] = useState<any>();
  const changeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };
  const selectHandler = (e: ISelect | null, name: string) => {
    setState((prev: any) => ({ ...prev, [name]: e!.value }));
  };
  const changeEditorHandler = (content: string, editor: Editor) => {
    setState((prev: any) => ({ ...prev, [editor?.id]: content }));
    if (!firstLoad.current) {
      setEditorData(editor);
    }
    firstLoad.current = false;
  };
  const switchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prev: any) => ({ ...prev, [e.target.name]: e.target.checked }));
  };
  const mappedDataBrands = brands?.map((brand: any) => ({
    label: brand.name,
    value: brand.id,
  }));
  const mappedDataBrandCats = modelCats?.map((cat: any) => ({
    label: cat.name,
    value: cat.id,
  }));
  const fileHandler = (e: any) => {
    setImage(e.target.files[0]);
  };
  const handleText = (text: string, stateKey: string) => {
    setState((prev: any) => ({ ...prev, [stateKey]: text }));
  };
  const editorKeys = ['text', 'description','specification']
  useEffect(() => {
    if (!!Number(id)) {
      AdminModelService.getSingle(id as unknown as number)
        .then((response) => {
          const { data } = response;
          setState({
            ...data,
            brand: data.brand.id,
            modelCategory: data.modelCategory.id,
          });
          data?.id && editorKeys.forEach(editorKey=>{
            InitEditorMulti(data?.[editorKey] ?? undefined, (e)=> handleText(e, editorKey), `.${editorKey}`);
          })
          window.scrollTo(0, 0);
        })
        .catch((error) => ErrorToast(error));
    }else{
        editorKeys.forEach(editorKey=>{
          InitEditorMulti(undefined, (e)=> handleText(e, editorKey), `.${editorKey}`);
        })
    }
  }, [id]);
  function removeElementsByClass(className: string) {
    const elements = document.getElementsByClassName(className);
    while (elements.length > 0) {
      elements && elements[0]?.parentNode?.removeChild(elements[0]);
    }
    setSelectEditor(false);
  }

  useEffect(() => {
    if (selectEditor) {
      removeElementsByClass(
        "jsplus_theme jsplus_ui_full_screen jsplus_ui_sidebar_widgets jsplus_show"
      );
    }
  }, [selectEditor]);
  return (
    <Card key={state?.id}>
      <Card.Body>
        <Row>
          <Col md={6} className="mb-3">
            <label htmlFor="title" className="required-field">
              {t("global.title")}
            </label>
            <input
              type="text"
              name="title"
              className="form-control"
              required
              onChange={changeHandler}
              value={state?.id && state?.title}
            />
          </Col>
          <Col md={6}>
            <label htmlFor="brand" className="required-field">
              {t("brand.brand")}
            </label>
            <Select
              className="react-select-field"
              onChange={(e) => selectHandler(e, "brand")}
              options={mappedDataBrands}
              value={mappedDataBrands?.find(
                (category: any) => category.value === state?.brand
              )}
            />
          </Col>
          <Col md={6}>
            <label htmlFor="model_cat" className="required-field">
              {t("model.model_cat")}
            </label>
            <Select
              className="react-select-field"
              onChange={(e) => selectHandler(e, "modelCategory")}
              options={mappedDataBrandCats}
              value={mappedDataBrandCats?.find(
                (category: any) => category.value === state?.modelCategory
              )}
            />
          </Col>
          <Col md={2} className="">
            <div className="form-check form-switch p-0">
              <label
                className="d-block text-start"
                htmlFor="flexSwitchCheckDefault"
              >
                {t("global.active")}
              </label>
              <div className="row">
                <input
                  className="form-check-input switch-custom ms-4 mt-2"
                  type="checkbox"
                  name={id ? "active" : "isActive"}
                  checked={id ? state?.active : state?.isActive}
                  onChange={switchHandler}
                  role="switch"
                  id="flexSwitchCheckDefault"
                />
              </div>
            </div>
          </Col>
          <Col md={12} className="mt-2">
            <label className="d-block text-start" htmlFor="text">
              {t("global.text")}
            </label>

            <textarea className="editor text" onChange={console.log}></textarea>
          </Col>
          <Col md={12} className="mt-2">
            <label className="d-block text-start" htmlFor="description">
              {t("global.description")}
            </label>
            <textarea className="editor description" onChange={console.log}></textarea>
          </Col>
          <Col md={12} className="mt-2">
            <label className="d-block text-start" htmlFor="specification">
              {t("global.specification")}
            </label>
            <textarea className="editor specification" onChange={console.log}></textarea>
          </Col>
          <Col md={12} className="mt-2">
            <div>
              <label htmlFor="imageFile"> {t("download.image")}</label>
              <input
                onChange={fileHandler}
                className="form-control"
                type="file"
                accept="image/*"
                id="imageFile"
              />
            </div>
            {state?.image && (
              <img
                className="mt-3 rounded show-img-form"
                alt="200x200"
                src={PICTURE_URL + state?.image.path}
                data-holder-rendered="true"
              />
            )}
          </Col>
        </Row>
        <div className="col-12 my-2 d-flex justify-content-end text-align-center">
          <div>
            <button
              className="btn btn-info"
              onClick={() => {
                navigate("/admin/model");
              }}
            >
              {t("global.cancel")}
            </button>
            <button className="btn btn-primary ms-3" type="submit">
              {state?.id ? `${t("global.update")}` : `${t("global.create")}`}
            </button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
