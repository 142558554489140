import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip } from "chart.js";
import "chartjs-adapter-date-fns";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

interface IProps {
    data: {
        count: number;
        date: string;
    }[]
}

export const BarChart = ({data}: IProps) => {


    const chartData = {
        labels: data.map((entry) => entry.date),
        datasets: [
            {
                label: "Count",
                data: data.map((entry) => entry.count),
                backgroundColor: "rgba(255, 0, 0,.7)",
            },
        ],
    };
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                type: "category",
                title: {
                    display: true,
                    text: "Date",
                },
            },
            y: {
                title: {
                    display: true,
                    text: "Count",
                },
                ticks: {
                    precision: 0,
                },
                beginAtZero: true,
            },
        },
    };

    return (
        <div style={{width: '100%', height: 400}}>
            <Bar data={chartData} options={options as any}/>
        </div>
    )
}


export default BarChart;