import React, {useEffect, useState} from 'react';
import List from "./components/List";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {AdminSpeakerSpecificationService} from "./service";
import {ErrorToast, SuccessToast} from "../../shared/toasters/toasters";
import {Pagination} from "../../shared/paginations/Paginations";
import {ConfirmDelete} from "../../shared/components/ConfirmDelete";
import DraggableBootstrapTable from "../../shared/components/draggableList/Draggable";
import {IPagination} from "../../shared/interface";

export interface ISpeakerSpecification {
    active: boolean;
    id?: number;
    name: string;
}

const SpeakerSpecification = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [list, setList] = useState<ISpeakerSpecification[]>([]);
    const [page, setPage] = useState(1);
    const [selectedPrompt, setSelectedPrompt] = useState<ISpeakerSpecification | undefined>(undefined);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [pagination, setPagination] = useState<IPagination>({
        page: 1,
        perPage: 10,
        term: "",
    });
    const handlePages = (updatePage: number) => {
        setPagination({...pagination, page: updatePage});
        setPage(updatePage);
    };
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const updatePosition = (newList: ISpeakerSpecification[]) => {
        const newListUpdated = newList?.map((item) => item.id) as number[];
        AdminSpeakerSpecificationService.updatePosition(newListUpdated);
    };
    const editHandler = (specificationId: number) => {
        navigate(`/admin/speaker-specification/update/${specificationId}`)
    }
    const deleteHandler = () => {
        selectedPrompt?.id &&
        AdminSpeakerSpecificationService.delete(selectedPrompt?.id)
            .then((response) => {
                if (response?.status === "success") {
                    SuccessToast(t(`speaker_specifications.successfully_deleted`));
                    setPagination(prev => ({...prev, updatedAt: new Date()?.getTime()}))
                }
            })
            .catch((error) => ErrorToast(error));
    };
    useEffect(() => {
        AdminSpeakerSpecificationService.getAll(pagination)
            .then(response => {
                const {data} = response;
                setList(data?.items);
                setTotalPages(Math.ceil(data?.totalItems / data?.perPage));
            }).catch(err => ErrorToast(err))
    }, [pagination]);

    return (
        <div>
            <div className="card">
                <div className="card-body d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center gap-3">
                        <div className="search-box">
                            <input
                                type="text"
                                className="form-control"
                                id="searchMemberList"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setPagination((prev) => ({
                                        ...prev,
                                        term: e.target.value,
                                    }))
                                }
                                placeholder={t("global.search")}
                            />
                            <i className="ri-search-line search-icon"></i>
                        </div>
                    </div>
                    <button
                        className="btn btn-primary"
                        onClick={() => navigate("/admin/speaker-specification/create")}
                    >
                        {t("global.add")}
                    </button>
                </div>
            </div>
            {page > 1 || pagination?.term ? (
                <List list={list}
                      setShowDeleteModal={setShowDeleteModal}
                      editHandler={editHandler}
                      setSelectedPrompt={setSelectedPrompt}
                />
            ) : (
                <DraggableBootstrapTable
                    preview={true}
                    list={list!}
                    setList={setList}
                    headers={["id", "name", "active"]}
                    title="speakerSpecification"
                    onEdit={editHandler}
                    selectedPrompt={selectedPrompt}
                    setSelectedPrompt={setSelectedPrompt}
                    handleShow={handleShow}
                    show={show}
                    setShow={setShow}
                    deleteHandler={deleteHandler}
                    updatePosition={updatePosition}
                />
            )}
            {page && (
                <div className="d-flex justify-content-end">
                    {totalPages <= 1 ? (
                        ""
                    ) : (
                        <Pagination
                            page={page}
                            totalPages={totalPages}
                            handlePagination={handlePages}
                        />
                    )}
                </div>
            )}
            <ConfirmDelete
                show={showDeleteModal}
                setShow={setShowDeleteModal}
                itemName={selectedPrompt?.name}
                deleteHandler={deleteHandler}
                selectedProduct={selectedPrompt}
            />
        </div>
    );
};

export default SpeakerSpecification;