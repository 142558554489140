import React, {SetStateAction} from 'react';
import {ISpeakerSpecification} from "../index";
import {Loader} from "../../../shared/components/Loader";
import {EmptyState} from "../../../shared/components/EmptyState";
import {Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface IProps{
    list: ISpeakerSpecification[];
    editHandler: (id: number)=> void;
    setShowDeleteModal: React.Dispatch<SetStateAction<boolean>>
    setSelectedPrompt: React.Dispatch<SetStateAction<ISpeakerSpecification | undefined>>
}
const List = ({list,setShowDeleteModal,setSelectedPrompt,editHandler}:IProps) => {
    const {t} = useTranslation();
    return (
        <div className="card">
            <div className="card-header pb-0">
                <div className="d-flex justify-content-between">
                    <h4 className="card-title mg-b-0">{t("car_specifications.list_of_specifications")}</h4>
                </div>
            </div>
            <div className="card-body">
                {list === undefined ? (
                    <Loader/>
                ) : list.length === 0 ? (
                    <EmptyState/>
                ) : (
                    <div className="table-responsive">
                        <Table className="align-middle table-nowrap mb-0">
                            <thead className="table-light">
                            <tr>
                                <th className="col-1">{t(`global.id`)}</th>
                                <th>{t(`table_headers.title`)}</th>
                                <th>{t(`table_headers.active`)}</th>
                                <th className="col-2 text-center">
                                    {t(`table_headers.actions`)}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {list?.map((prompt: ISpeakerSpecification) => {
                                return (
                                    <tr key={prompt?.id}>
                                        <td>
                                            <Link to="#" className="fw-medium">
                                                #{prompt?.id}
                                            </Link>
                                        </td>
                                        <td>{prompt?.name ?? "-"}</td>

                                        <td
                                            className={`text-${
                                                prompt?.active ? "success" : "danger"
                                            }`}
                                        >
                                            {prompt?.active ? (
                                                <span>
                                                    <i className="ri-checkbox-circle-line fs-17 align-middle me-1"/>
                                                    {t(`global.active`)}
                                                </span>
                                            ) : (
                                                <span>
                                                    <i className="ri-close-circle-line fs-17 align-middle me-1"/>
                                                    {t(`global.inactive`)}
                                                </span>
                                            )}
                                        </td>
                                        <td className="col-2">
                                            <div className="d-flex align-items-center justify-content-center">
                                                <button
                                                    className="btn btn-sm btn-warning d-flex align-items-center me-2"
                                                    onClick={() => editHandler(prompt?.id as number)}
                                                >
                                                    <i className="ri-edit-2-fill me-1"/>
                                                    {t("global.edit")}
                                                </button>
                                                <button
                                                    className="btn btn-sm btn-danger d-flex align-items-center"
                                                    onClick={() => {
                                                        setShowDeleteModal(true)
                                                        setSelectedPrompt(prompt);
                                                    }}
                                                >
                                                    <i className="ri-delete-bin-fill me-1"/>
                                                    {t("global.delete")}
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </Table>
                    </div>
                )}
            </div>
        </div>
    );
};

export default List;