import React, {ChangeEvent, SetStateAction, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {ErrorToast} from "../../../shared/toasters/toasters";
import {Card, Col, Row} from "react-bootstrap";
import {ISubscriberAutoInformation, ISubscriberType} from "../interface";
import {AdminSubscriberAutoInformationService} from "../service";
import {InitEditor} from "../../../shared/tinyMce/InitEditor";
import DatePicker from "react-datepicker";
import Select from "react-select";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";

interface IProps {
    state: ISubscriberAutoInformation | null;
    setState: React.Dispatch<SetStateAction<ISubscriberAutoInformation | null>>;
}

const CreateForm = ({state, setState}: IProps) => {
    const {t} = useTranslation();
    const {id} = useParams();
    const navigate = useNavigate();
const {businessPackages} = useSelector((state:RootState)=> state.enum)
    function handleChange(event: ChangeEvent<HTMLInputElement>, isNumber = false) {
        const { name, value, valueAsNumber } = event.target;
        setState((prev) => ({
            ...prev,
            [name]: isNumber ? valueAsNumber : value
        }) as ISubscriberAutoInformation);
    }


    console.log(state)

    const switchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({...prev, [e.target.name]: e.target.checked}) as ISubscriberAutoInformation);
    };

    const handleText = (comment: string) => {
        setState((prev) => ({...prev, comment}) as ISubscriberAutoInformation);
    };
    const selectHandler = (e: any) => {
        setState((prev: any) => ({ ...prev, type: e.value }));
    };
    const mappedTypes = businessPackages?.map((type: string) => ({
        label: t(`enums.${type}`),
        value: type,
    }));

    useEffect(() => {
        if (id) {
            AdminSubscriberAutoInformationService.getSingle(+id!)
                .then((response) => {
                    const {data} = response;
                    InitEditor(data?.comment, handleText);
                    setState({...data, category: data?.category?.id});
                })
                .catch((error) => ErrorToast(error));

        } else {
            InitEditor(undefined, handleText);
        }
    }, [id]);
    return (
        <Card>
            <Card.Body>
                <Row>
                    <Col md={4} className={'mb-3'}>
                        <label htmlFor="name">
                            {t("global.name")}
                        </label>
                        <input type="text"
                               name={'name'}
                               value={state?.name}
                               onChange={handleChange}
                               className={'form-control'}
                               required
                        />
                    </Col>
                    <Col md={4} className={'mb-3'}>
                        <label htmlFor="email">
                            {t("global.email")}
                        </label>
                        <input type="email"
                               name={'email'}
                               value={state?.email}
                               onChange={handleChange}
                               className={'form-control'}
                               required
                        />
                    </Col>
                    <Col md={4} className={'mb-3'}>
                        <label htmlFor="aboCode">
                            {t("global.aboCode")}
                        </label>
                        <input type="text"
                               name={'aboCode'}
                               value={state?.aboCode}
                               onChange={handleChange}
                               className={'form-control'}
                               required
                        />
                    </Col>
                    <Col md={2}>
                        <label htmlFor="registeredDevice">
                            {t("subscribers.registered_device")}
                        </label>
                        <input type="number"
                               name={'registeredDevice'}
                               value={state?.registeredDevice}
                               onChange={handleChange}
                               className={'form-control'}
                               required
                        />
                    </Col>
                    <Col md={2}>
                        <label htmlFor="numberOfDevice">
                            {t("subscribers.number_of_devices")}
                        </label>
                        <input type="number"
                               name={'numberOfDevice'}
                               value={state?.numberOfDevice}
                               onChange={handleChange}
                               className={'form-control'}
                               required
                        />
                    </Col>
                    <Col md={4}>
                        <label htmlFor="date">
                            {t("global.date")}
                        </label>
                        <div className="datepicker-container">
                            <DatePicker
                                className="date-picker"
                                dateFormat="dd/MM/yyyy"
                                selected={state?.validTo && new Date(state?.validTo)}
                                onChange={(validTo) =>
                                    setState((prev: any) => ({...prev, validTo: validTo}))
                                }
                                isClearable
                                placeholderText={t('global.choose_date')}
                                required
                            />
                        </div>
                    </Col>
                    <Col md={3}>
                        <label htmlFor="types" className="required-field">
                            {t("table_headers.firm_types")}
                        </label>
                        <Select
                            id={'types'}
                            className="react-select-field"
                            onChange={selectHandler}
                            options={mappedTypes}
                            required
                            value={mappedTypes?.find(
                                (data: any) => data.value === state?.type
                            )}
                        />
                    </Col>
                    <Col md={1}>
                        <div className="d-flex flex-column form-check form-switch p-0">
                            <label htmlFor="active">
                                {t("global.active")}
                            </label>
                            <input
                                className="form-check-input switch-custom ms-0 mt-2"
                                type="checkbox"
                                name={"active"}
                                checked={state?.active}
                                onChange={switchHandler}
                                role="switch"
                                id="active"
                            />
                        </div>
                    </Col>

                    <Col md={12} className={'my-3'}>
                        <label className="d-block text-start" htmlFor="comment">
                            {t("global.isComment")}
                        </label>

                        <textarea className="editor imprint" onChange={console.log}></textarea>
                    </Col>
                    <Col md={12} className={'d-flex align-items-end justify-content-end mt-3'}>
                        <button
                            className="btn btn-info me-3"
                            type={"button"}
                            onClick={() => navigate("/admin/editions")}
                        >
                            {t("global.cancel")}
                        </button>
                        <button className="btn btn-primary" type="submit">
                            {t(state?.id ? "global.update" : "global.create")}
                        </button>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default CreateForm;