import {Card, Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import React, {SetStateAction} from "react";
import {INewsCategoryAutoInformation} from "../interface";

interface IProps {
    state: INewsCategoryAutoInformation | null;
    setState: React.Dispatch<SetStateAction<INewsCategoryAutoInformation | null>>;
    setIsMenuOpened: React.Dispatch<SetStateAction<boolean>>
}
export const CreateForm = ({
                               state,
                               setState,
                               setIsMenuOpened,
                           }: IProps) => {
    const { t } = useTranslation();
    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setState((prev) => ({ ...prev, [name]: value }) as INewsCategoryAutoInformation);
    };
    const switchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({...prev, [e.target.name]: e.target.checked}) as INewsCategoryAutoInformation);
    };
    return (
        <Card>
            <Card.Body>
                <Row>
                    <Col md={4}>
                        <label htmlFor="name" className="required-field">
                            {t("global.title")}
                        </label>
                        <input
                            type="text"
                            name="title"
                            className="form-control"
                            required
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeHandler(e)}
                            value={state?.id && state?.title}
                        />
                    </Col>
                    <Col md={4}>
                        <div className="form-check form-switch p-0">
                            <label htmlFor="active">
                                {t("global.active")}
                            </label>
                            <div className="row">
                                <input
                                    className="form-check-input switch-custom ms-4 mt-2"
                                    type="checkbox"
                                    name={"active"}
                                    checked={state?.active}
                                    onChange={switchHandler}
                                    role="switch"
                                    id="active"
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className="col-12 my-2 d-flex justify-content-end text-align-center">
                    <div>
                        <button
                            className="btn btn-info"
                            onClick={() => {
                                setIsMenuOpened(false);
                                setState(null);
                            }}
                        >
                            {t("global.cancel")}
                        </button>
                        <button className="btn btn-primary ms-3" type="submit">
                            {state?.id ? `${t("global.update")}` : `${t("global.create")}`}
                        </button>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};
