import React, {ChangeEvent, SetStateAction} from 'react';
import {Card, Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {ISpeakerSpecification} from "../index";

interface IProps {
    state: ISpeakerSpecification | null;
    setState: React.Dispatch<SetStateAction<ISpeakerSpecification | null>>
}

const CreateForm = ({state, setState}: IProps) => {
    const {t} = useTranslation();

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        const {name, value} = event.target;
        setState((prev) => ({...prev, [name]: value}) as ISpeakerSpecification);
    }

    const switchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({...prev, [e.target.name]: e.target.checked}) as ISpeakerSpecification);
    };
    return (
        <Card>
            <Card.Body>
                <Row>
                    <Col md={6}>
                        <label
                            className="d-block text-start"
                            htmlFor="name"
                        >
                            {t("global.specification")}
                        </label>
                        <input type="text"
                               name={'name'}
                               value={state?.name}
                               onChange={handleChange}
                               placeholder={'Specification name'}
                               className={'form-control'}
                        />
                    </Col>
                    <Col md={3}>
                        <div className="form-check form-switch p-0">
                            <label
                                className="d-block text-start"
                                htmlFor="flexSwitchCheckDefault"
                            >
                                {t("global.active")}
                            </label>
                            <div className="row">
                                <input
                                    className="form-check-input switch-custom ms-4 mt-2"
                                    type="checkbox"
                                    name={"active"}
                                    checked={state?.active}
                                    onChange={switchHandler}
                                    role="switch"
                                    id="flexSwitchCheckDefault"
                                />
                            </div>
                        </div>
                    </Col>
                    <Col md={3} className={'d-flex align-items-end justify-content-end'}>
                        <button className="btn btn-primary" type="submit">
                            {t(state?.id ? "global.update" : "global.create")}
                        </button>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default CreateForm;